export const styles = {
	header: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center'
	},
	headerButton: {
		marginLeft: 'auto'
	},
	modalTitle: {
		fontSize: '18px',
		fontFamily: 'Helvetica Neue, Helvetica, Arial, sans- serif',
		color: '#63666A',
		textAlign: 'center'
	},
	modalSubTitle: {
		fontSize: '14px',
		fontFamily: 'Helvetica Neue, Helvetica, Arial, sans- serif',
		color: '#63666A',
		textAlign: 'center'
	}
};
