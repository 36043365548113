import React from 'react';
import ReactDOM, {render} from 'react-dom';
import {StoreProvider} from 'easy-peasy';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'primereact/resources/themes/nova/theme.css';
import '@uimf/icons/webfonts/stylesheets/micro-interaction-icons.css';
import 'react-toastify/dist/ReactToastify.min.css';
import './index.css';
import {ErrorBoundary} from './components/utilities/error-boundaries/error-boundary';
import {store} from './stores/global';
import {AppProvider} from './app-provider';

const entryPoint = document.querySelector('#root');

render(
	<ErrorBoundary>
		<StoreProvider store={store}>
			<AppProvider />
		</StoreProvider>
	</ErrorBoundary>,
	entryPoint
);

if (module.hot) {
	module.hot.accept('./app', () => {
		const NextAppProvider = require('./app-provider').default;
		ReactDOM.render(
			<ErrorBoundary>
				<StoreProvider store={store}>
					<NextAppProvider />{' '}
				</StoreProvider>
			</ErrorBoundary>,
			entryPoint
		);
	});
}
