import React from 'react';
import {PropTypes} from 'prop-types';
import {Box, Text, Button} from '@chakra-ui/react';
import {styleConfig} from './toast-body.style';
import {useChakraStyleMerge} from '../../../../common/hooks/use-chakra-style-merge';

export const ToastBody = ({closeToast, type, message, size}) => {
	const chakraStyles = useChakraStyleMerge({
		styleConfig,
		size,
		variant: type
	});

	const createMessageBlock = message => {
		if (Array.isArray(message)) {
			return (
				<>
					{message.map((item, index) => {
						if (Array.isArray(item)) {
							return createListBlock(item, 0, index);
						}

						return createMessageLineBlock(item, index);
					})}
				</>
			);
		}

		return message;
	};

	const createMessageLineBlock = (message, itemIndex) => {
		return (
			<span key={`0_${itemIndex}_${message}`}>
				{message}
				<br></br>
			</span>
		);
	};

	const createListItemBlock = (message, level, itemIndex) => {
		return <li key={`${level}_${itemIndex}_${message}`}>{message}</li>;
	};

	const createListBlock = (messageList, level, itemIndex) => {
		return (
			<ul key={`${level}_${itemIndex}`}>
				{messageList.map((item, index) => {
					return Array.isArray(item)
						? createListBlock(item, level + 1, index)
						: createListItemBlock(item, level + 1, index);
				})}
			</ul>
		);
	};

	return (
		<Box sx={chakraStyles.main} onClick={closeToast}>
			<Text as="span" sx={chakraStyles.messageContainer}>
				<Text sx={chakraStyles.message}>{createMessageBlock(message)}</Text>
			</Text>
			<Button
				sx={chakraStyles.button}
				leftIcon={<Text as="span" className="pi pi-times" />}
			></Button>
		</Box>
	);
};

ToastBody.propTypes = {
	closeToast: PropTypes.func,
	message: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
	type: PropTypes.string,
	size: PropTypes.string
};
