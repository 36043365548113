const stringYear = date => `${date.getFullYear().toString().padStart(4, '0')}`;

const stringMonth = date => `${(date.getMonth() + 1).toString().padStart(2, '0')}`;

const stringDay = date => `${date.getDate().toString().padStart(2, '0')}`;

const string12Hour = date => `${(((date.getHours() - 24) % 12) + 12).toString()}`;

//const string24Hour = date => `${date.getHours().toString().padStart(2, '0')}`;

const stringMinute = date => `${date.getMinutes().toString().padStart(2, '0')}`;

const stringSecond = date => `${date.getSeconds().toString().padStart(2, '0')}`;

//const stringAmPm = date => `${date.getHours() < 12 ? 'AM' : 'PM'}`;

/*
	Input: Date object
	Output: Date-formatted string "YYYY-MM-DD HH:mm:ss"
*/

export const getFormattedDate = date => {
	return `${stringYear(date)}-${stringMonth(date)}-${stringDay(date)} ${string12Hour(
		date
	)}:${stringMinute(date)}:${stringSecond(date)}`;
};
