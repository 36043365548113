export const primaryNavModel = {
	applicationName: '',
	homePath: '/',
	menuData: [
		{
			icon: '',
			label: 'Exploration',
			items: [
				{
					icon: '',
					label: 'Data Load Status',
					to: '/exploration/data-load-status'
				},
				{
					icon: '',
					label: 'Performance Dashboard',
					to: '/exploration/performance-dashboard'
				},
				{
					icon: '',
					label: 'Analysis Dashboard',
					to: '/exploration/analysis-dashboard'
				},
				{
					icon: '',
					label: 'Facility Analysis Dashboard',
					to: '/exploration/facility-analysis-dashboard'
				},
				{
					icon: '',
					label: 'Vendor Insourcing',
					to: '/exploration/vendor-insourcing'
				},
				{
					icon: '',
					label: 'ODAR Word Cloud',
					to: '/exploration/odar-word-cloud'
				},
				{
					icon: '',
					label: 'ODAR Word Association',
					to: '/exploration/odar-word-association'
				}
			]
		},
		{
			icon: '',
			label: 'Investigation',
			items: [
				{
					icon: '',
					label: 'Select Data',
					to: '/investigation/select-data'
				},
				{
					icon: '',
					label: 'User Lists',
					to: '/investigation/user-lists'
				},
				{
					icon: '',
					label: 'Data Grid (PARS)',
					to: '/investigation/data-grid'
				},
				{
					icon: '',
					label: 'Claims Research Tracking - United',
					to: '/investigation/claims-research-tracking-united'
				},
				{
					icon: '',
					label: 'Claims Research Tracking - Commercial',
					to: '/investigation/claims-research-tracking-commercial'
				}
			]
		},
		{
			icon: '',
			label: 'Preferences',
			items: [
				{
					icon: '',
					label: 'Filter Options',
					to: '/preferences/filter-options'
				},
				{
					icon: '',
					label: 'Select Data Default',
					to: '/preferences/select-data-default'
				}
			]
		},
		{
			icon: '',
			label: 'Tools',
			items: [
				{
					icon: '',
					label: 'PAWS',
					to: '/tools/paws'
				},
				{
					icon: '',
					label: 'Provider Profiler',
					to: '/tools/provider-profiler'
				},
				{
					icon: '',
					label: 'Team Track',
					to: '/tools/team-track'
				},
				{
					icon: '',
					label: 'User Reporting',
					to: '/tools/user-reporting'
				},
				{
					icon: '',
					label: 'CIA',
					to: '/tools/cia'
				},
				{
					icon: '',
					label: 'Matomo Tracking',
					to: '/tools/matomo-tracking'
				}
			]
		},
		{
			icon: '',
			label: 'Support',
			items: [
				{
					icon: '',
					label: 'Contact Us',
					to: '/support/contact-us'
				},
				{
					icon: '',
					label: 'Submit an Idea / Report an Issue',
					to: '/support/report'
				},
				{
					icon: '',
					label: 'Hub Connect',
					to: '/support/hub-connect'
				}
			]
		},
		{
			icon: '',
			label: 'Admin',
			items: [
				{
					icon: '',
					label: 'Netezza Query Dashboard',
					to: '/admin/netezza-query-dashboard'
				},
				{
					icon: '',
					label: 'PARS Wiki Site',
					to: '/admin/pars-wiki-site'
				},
				{
					icon: '',
					label: 'PARS Git Hub',
					to: '/admin/pars-git-hub'
				},
				{
					icon: '',
					label: 'CA Agile',
					to: '/admin/ca-agile'
				},
				{
					icon: '',
					label: 'PARS Load Status',
					to: '/admin/pars-load-status'
				},
				{
					icon: '',
					label: 'Manage System Maintenance Switch',
					to: '/admin/manage-system-maintenance-switch'
				},
				{
					icon: '',
					label: 'Manage System Maintenance Messages',
					to: '/admin/manage-system-maintenance-messages'
				},
				{
					icon: '',
					label: 'Manage System Messages',
					to: '/admin/manage-system-messages'
				},
				{
					icon: '',
					label: 'Manage Stop Words',
					to: '/admin/manage-stop-words'
				},
				{
					icon: '',
					label: 'Query Runtimes',
					to: '/admin/query-runtimes'
				}
			]
		}
	]
};
