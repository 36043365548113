import React, {useRef} from 'react';
import {
	AlertDialog,
	AlertDialogBody,
	AlertDialogFooter,
	AlertDialogHeader,
	AlertDialogContent,
	AlertDialogOverlay,
	Button,
	Divider
} from '@chakra-ui/react';
import PropTypes from 'prop-types';

export const DeleteFilterDialog = ({isOpen, onClose, onConfirm}) => {
	const cancelRef = useRef(null);

	return (
		<AlertDialog
			size="xs"
			isOpen={isOpen}
			leastDestructiveRef={cancelRef}
			onClose={onClose}
		>
			<AlertDialogOverlay>
				<AlertDialogContent>
					<AlertDialogHeader fontSize="lg" fontWeight="bold">
						{'Delete Filter'}
					</AlertDialogHeader>
					<Divider />
					<AlertDialogBody>
						{'Are you sure you want to delete?'}
					</AlertDialogBody>
					<Divider />
					<AlertDialogFooter>
						<Button colorScheme="red" onClick={onConfirm}>
							{'Delete'}
						</Button>
						<Button ref={cancelRef} ml={3} onClick={onClose}>
							{'Cancel'}
						</Button>
					</AlertDialogFooter>
				</AlertDialogContent>
			</AlertDialogOverlay>
		</AlertDialog>
	);
};

DeleteFilterDialog.propTypes = {
	isOpen: PropTypes.bool.isRequired,
	onClose: PropTypes.func.isRequired,
	onConfirm: PropTypes.func.isRequired
};
