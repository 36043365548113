import React from 'react';
import {CircularProgress} from '@chakra-ui/react';
import {styles as styleConfig} from './loader-display.style';

export const LoaderDisplay = () => {
	return (
		<div style={styleConfig.baseStyle.progressIcon}>
			<CircularProgress
				isIndeterminate
				color="green.300"
				testid="progressIcon"
				style={styleConfig.baseStyle.spinner}
			/>
		</div>
	);
};
