import React from 'react';
import {Box} from '@chakra-ui/react';
import {MainContentModel} from '../models/main-content';
import {TilesContainer} from './home/tiles/tiles-container';
import {DisplayContent} from './home/display-content/display';

export const HomePage = () => {
	return (
		<div>
			<Box>
				<TilesContainer imageData={MainContentModel.imageData} />
			</Box>
			&nbsp;
			<Box>
				<DisplayContent sections={MainContentModel.sections} />
			</Box>
		</div>
	);
};
