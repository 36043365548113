import React from 'react';
import {PropTypes} from 'prop-types';
import {Box} from '@chakra-ui/react';
import {styleConfig} from './main-content.style';
import {useChakraStyleMerge} from '../../../common/hooks/use-chakra-style-merge';

export const MainContent = props => {
	const {size, variant, ...rest} = props;
	const styles = useChakraStyleMerge({
		themeKey: 'MainContent',
		styleConfig,
		variant,
		size
	});
	return <Box sx={styles.container} className="mainContent" {...rest} />;
};

MainContent.propTypes = {
	size: PropTypes.string,
	variant: PropTypes.string
};
