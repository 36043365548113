import React, {useState, useEffect} from 'react';
import {styles} from './filter-options.style';
import {CheckboxGroup, Checkbox, Text, Stack} from '@chakra-ui/react';
import PropTypes from 'prop-types';

export const FilterCheckboxGroup = ({
	options,
	name,
	preFilters,
	setClientTypeValue,
	onUpdateFormValues,
	clearCheckBoxes
}) => {
	const [isChecked, setIsChecked] = useState([]);

	useEffect(() => {
		if (preFilters && preFilters.length > 0) {
			const checkedValues = options
				.filter(option => preFilters.includes(option.name))
				.map(option => option.name);
			setIsChecked(checkedValues);
		} else {
			setIsChecked([]);
		}
	}, [preFilters]);

	useEffect(() => {
		const formValues = options.reduce((accumulator, option) => {
			if (isChecked.includes(option.name)) {
				accumulator.push(option.name);
			}
			return accumulator;
		}, []);
		onUpdateFormValues(name, formValues);
	}, [isChecked]);

	useEffect(() => {
		if (clearCheckBoxes === true) {
			setIsChecked([]);
		}
	}, [clearCheckBoxes]);

	const handleFilterChange = newFilters => {
		setIsChecked(newFilters);
		if (
			name === 'Client Type' &&
			newFilters.includes('Commercial') &&
			!newFilters.includes('United')
		) {
			setClientTypeValue(true);
		} else {
			setClientTypeValue(false);
		}
	};

	return (
		<div>
			<strong>
				<Text sx={styles.subtitle}>{name}</Text>
			</strong>
			<CheckboxGroup
				options={options}
				value={isChecked}
				onChange={handleFilterChange}
				size="sm"
				colorScheme="orange"
			>
				{options.map(option => (
					<Stack spacing={4} direction="column" key={option.name}>
						<Checkbox value={option.name} mb="7px">
							{option.name}
						</Checkbox>
					</Stack>
				))}
			</CheckboxGroup>
			<div style={{padding: '10px'}}></div>
		</div>
	);
};

FilterCheckboxGroup.propTypes = {
	options: PropTypes.arrayOf(
		PropTypes.shape({
			name: PropTypes.string
		})
	),
	name: PropTypes.string.isRequired,
	preFilters: PropTypes.arrayOf(PropTypes.string),
	setClientTypeValue: PropTypes.func,
	onUpdateFormValues: PropTypes.func,
	clearCheckBoxes: PropTypes.bool
};
