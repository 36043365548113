import {store} from '../stores/global';

export const acquireToken = async request => {
	const msalInstance = store.getState().msal.instance;
	const activeAccount = msalInstance.getActiveAccount(); // This will only return a non-null value if you have logic somewhere else that calls the setActiveAccount API
	const accounts = msalInstance.getAllAccounts();

	if (!activeAccount && accounts.length === 0) {
		/*
		 * User is not signed in. Throw error or wait for user to login.
		 * Do not attempt to log a user in outside of the context of MsalProvider
		 */
		return msalInstance.loginRedirect(request);
	}

	const activeRequest = {
		...request,
		account: activeAccount || accounts[0]
	};

	const authResult = await msalInstance.acquireTokenSilent(activeRequest);

	return authResult.accessToken;
};

export const onSignOut = () => {
	const msalInstance = store.getState().msal.instance;
	const accountID = msalInstance.accountID;
	msalInstance.logoutRedirect({accountID});
};

export const authRequest = () => {
	return {
		scopes: ['openid', 'api://6292646c-d802-4203-a738-84f9197d9c0d/pars-ui-scope']
	};
};
