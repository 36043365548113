import {colors} from './colors';
import {sizes} from './sizes';

const subformBase = {
	fontSize: sizes.twoUnit,
	'& h3': {
		fontSize: 'calc(2.5 * var(--unit))',
		color: colors.orange[400]
	},
	'& p': {
		fontSize: 'calc(1.5 * var(--unit))',
		color: 'black'
	},
	'& .actiondropdown': {
		backgroundColor: 'red'
	},
	'& .form-group': {
		marginBottom: 0
	}
};

export const components = {
	Subform: {
		baseStyle: {
			fontSize: sizes.twoUnit,
			'& h3': {
				fontSize: 'calc(2.5 * var(--unit))',
				color: colors.orange[400]
			},
			'& .actiondropdown': {
				backgroundColor: 'red'
			},
			'& .card': {
				fontSize: 'calc(1.8 * var(--unit))',
				marginTop: '0.5em'
			},
			'& .cardColumn': {
				p: 0
			},
			'& .cardheader': {
				p: '0.5em 1em 0.5em 1em',
				fontSize: 'calc(1.5 * var(--unit))',
				fontWeight: 'bold'
			},
			'& .primarycard': {
				fontSize: 'calc(1.8 * var(--unit))',
				marginTop: '0.5em',
				height: '98%'
			},
			'& .cardbody': {
				p: '0.01em 0.5em 0.01em 0.5em'
			}
		}
	},
	SubInfoForm: {
		baseStyle: {
			...subformBase
		}
	},
	SubformGrid: {
		baseStyle: {
			...subformBase,
			fontSize: sizes.twoUnit,
			'& label input[type="checkbox"]': {
				display: 'inline-block',
				width: 'auto',
				marginRight: '8px',
				justifyContent: 'center',
				alignItems: 'center'
			}
		}
	}
};
