import {apiClient} from '../client/index';
const basepath = 'filter';

export const getClientType = async () => {
	return apiClient()
		.get(`${basepath}/clienttype`)
		.then(response => response.data);
};

export const getClientName = async () => {
	return apiClient()
		.get(`${basepath}/clientname`)
		.then(response => response.data);
};

export const getPlatform = async () => {
	return apiClient()
		.get(`${basepath}/platform`)
		.then(response => response.data);
};

export const getVendorType = async () => {
	return apiClient()
		.get(`${basepath}/vendortype`)
		.then(response => response.data);
};

export const getVendorName = async () => {
	return apiClient()
		.get(`${basepath}/vendorname`)
		.then(response => response.data);
};

export const getLineOfBusiness = async () => {
	return apiClient()
		.get(`${basepath}/lob`)
		.then(response => response.data);
};

export const getAuditCategory = async () => {
	return apiClient()
		.get(`${basepath}/auditcategory`)
		.then(response => response.data);
};

export const getSubCategory = async () => {
	return apiClient()
		.get(`${basepath}/subcategory`)
		.then(response => response.data);
};

export const getDates = async () => {
	return apiClient()
		.get(`${basepath}/date`)
		.then(response => response.data);
};

export const getFlags = async () => {
	return apiClient()
		.get(`${basepath}/flags`)
		.then(response => response.data);
};
