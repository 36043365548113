const baseStyles = {
	fontFamily: 'Helvetica Neue, Helvetica, Arial, sans- serif',
	color: '#63666A'
};

export const styles = {
	outerContainer: {
		border: '2px solid #dfdfdd',
		borderRadius: '4px',
		padding: '15px',
		position: 'relative',
		left: '28%',
		width: '42%'
	},
	container: {
		gap: '2px',
		borderRadius: '4px',
		padding: '10px',
		maxHeight: '1000px',
		maxWidth: '550px',
		position: 'relative'
	},
	title: {
		fontSize: '30px',
		fontFamily: baseStyles.fontFamily,
		color: baseStyles.color,
		textAlign: 'center',
		padding: '15px'
	},
	subtitle: {
		fontSize: '15px',
		fontFamily: baseStyles.fontFamily,
		color: baseStyles.color,
		paddingBottom: '8px'
	},
	radioGroup: {
		color: '#63666A',
		fontFamily: 'Helvetica Neue, Helvetica, Arial, sans- serif',
		textOverflow: 'ellipsis',
		paddingBottom: '10px'
	}
};
