import React from 'react';
import {ToastContainer as ToastifyContainer} from 'react-toastify';

export const ToastContainer = props => {
	const defaultSettings = {
		theme: 'colored',
		draggable: true,
		closeButton: false,
		closeOnClick: true
	};

	const settings = {...defaultSettings, ...props};

	return <ToastifyContainer {...settings} />;
};
