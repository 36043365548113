export const styles = {
	display: {
		display: 'flex',
		flexWrap: 'wrap',
		flexDirection: 'column',
		alignItems: 'center',
		width: '283px',
		height: '146px',
		backgroundColor: '#efefee',
		borderRadius: '5px',
		overflow: 'hidden',
		boxShadow: '1px 1px 1px',
		cursor: 'pointer',
		_hover: {boxShadow: '2px 2px 2px'}
	},
	container: {
		display: 'flex',
		flexWrap: 'wrap',
		flexDirection: 'row',
		justifyContent: 'space-between',
		padding: '2px 80px 32px 80px',
		rowGap: '2em',
		position: 'relative',
		left: '5%',
		width: '90%'
	},
	hoverText: {
		position: 'absolute',
		backgroundColor: 'white',
		color: 'black',
		borderRadius: 'sm',
		borderBlock: 'sm',
		fontSize: '0.75em',
		border: '1px solid black',
		padding: '5px 16px 5px 16px'
	},
	img: {
		width: '200px',
		height: '110px',
		padding: '12px 0px 0px 0px'
	},
	text: {
		fontSize: '14px',
		textAlign: 'center',
		padding: '6px 0px 2px 0px',
		fontFamily: 'Helvetica Neue, Helvetica, Arial, sans- serif',
		color: '#63666A'
	}
};
