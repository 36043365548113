import React from 'react';
import {Link as RouterLink} from 'react-router-dom';
import {Box, Flex, Text, List, ListItem, Link} from '@chakra-ui/react';
import {footer} from '../../../models/footer';
import {styles as styleConfig} from './footer.style';

export const Footer = () => {
	return (
		<Box sx={styleConfig.variants.stickyFooter.container}>
			<Flex align="space-between">
				<Text data-testid="footer-trademark" sx={styleConfig.baseStyle.trademark}>
					{`\u00A9 ${new Date().getFullYear()} Optum, Inc. All rights reserved.`}
				</Text>
				<List sx={styleConfig.baseStyle.links}>
					{footer.links.map(lnk => {
						return (
							<ListItem
								key={lnk.title}
								sx={styleConfig.baseStyle.linkListListItem}
							>
								<Link
									data-testid="link"
									as={RouterLink}
									to={lnk.link}
									sx={styleConfig.baseStyle.link}
								>
									{lnk.title}
								</Link>
							</ListItem>
						);
					})}
				</List>
			</Flex>
		</Box>
	);
};
