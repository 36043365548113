import {apiClient} from '../client/index';
const basepath = 'grid/pars';
const filterBasePath = 'filter';
const saveFilterBasePath = 'userfilter';
const claimDetailDataPath = 'detaildata';

export const getDataGridValues = async () => {
	return apiClient()
		.get(`${basepath}/columns`)
		.then(response => response.data);
};

export const getDataGridData = async model => {
	return apiClient()
		.post(`${basepath}/data`, model)
		.then(response => response.data);
};

export const getFilterFlags = async model => {
	return apiClient()
		.get(`${filterBasePath}/flags`, model)
		.then(response => response.data);
};

export const getSaverFilter = async model => {
	return apiClient()
		.get(`${saveFilterBasePath}/name/${model}`, model)
		.then(response => response.data);
};

export const saveFilter = async model => {
	return apiClient()
		.put(`${saveFilterBasePath}/update/0`, model)
		.then(response => response.data);
};

export const getClaimDetailData = async model => {
	return apiClient()
		.get(`${claimDetailDataPath}/fln?uid=127902410`)
		.then(response => response.data);
};
export const getClaimQuery = async model => {
	return apiClient()
		.get(`${claimDetailDataPath}/queries?uid=127902410`, model)
		.then(response => response.data);
};

const claimData = 'detaildata/data/claimidbase/';

export const getClaimQueryDetailData = async model => {
	return apiClient()
		.get(
			`${claimData}COSMOSATL00010462932/query/${model.sqlDetailQueryId}?uid=127902410`,
			model
		)
		.then(response => response.data);
};
