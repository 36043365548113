import React from 'react';
import {Flex} from '@chakra-ui/react';
import PropTypes from 'prop-types';
import {styles} from './tile.style';
import {Tile} from './tile';

export const TilesContainer = ({imageData}) => {
	return (
		<Flex data-testid="tiles-container" sx={styles.container}>
			{imageData.map((item, index) => (
				<Tile
					key={index}
					image={item.image}
					title={item.title}
					link={item.link}
					hoverText={item.hoverText}
				/>
			))}
		</Flex>
	);
};

const imageDataItem = {
	image: PropTypes.string.isRequired,
	title: PropTypes.string.isRequired,
	hoverText: PropTypes.string.isRequired,
	link: PropTypes.string.isRequired
};

TilesContainer.propTypes = {
	imageData: PropTypes.arrayOf(PropTypes.shape(imageDataItem)).isRequired
};
