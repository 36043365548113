import {action} from 'easy-peasy';

export const user = {
	name: '',
	settings: {},
	setName: action((state, payload) => {
		state.name = payload;
	}),
	setSettings: action((state, payload) => {
		state.settings = payload;
	})
};
