import {colors} from './colors';
import {components} from './components';

const font = 'Arial, Helvetica, sans-serif';

const fonts = {
	heading: font,
	body: font,
	mono: font
};

export const theme = {
	colors,
	fonts,
	styles: {
		global: {
			// ck-editor
			'& :root': {
				'--ck-z-modal-custom': '9999'
			},
			'& .ck-balloon-panel': {
				zIndex: 'var(--ck-z-modal-custom) !important'
			}
		}
	},
	config: {
		initialColorMode: 'light'
	},
	components,
	breakpoints: {
		sm: '320px',
		md: '720px',
		lg: '960px',
		xl: '1024px',
		'2xl': '1200px'
	}
};
