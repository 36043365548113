import React from 'react';
import {Flex, Image, Text} from '@chakra-ui/react';
import {Button} from 'primereact/button';
import PropTypes from 'prop-types';
import {styles} from './display.style';

export const Section = ({title, text, link, button, icon}) => {
	const handleButtonClick = () => {
		window.open(link, '_blank');
	};

	return (
		<div data-testid="section">
			<Flex sx={styles.display1}>
				<Image
					data-testid="section-image"
					src={icon}
					sx={styles.img}
					alt="image"
				/>
				<Text data-testid="section-title" sx={styles.title}>
					{title}
				</Text>
				<Text data-testid="section-text" sx={styles.text}>
					{text}
				</Text>
			</Flex>
			<Flex sx={styles.display2}>
				<Button
					data-testid="section-button"
					label={button}
					onClick={handleButtonClick}
					style={{
						background: 'black',
						width: '120px',
						fontSize: '14px',
						fontFamily: 'Helvetica Neue, Helvetica, Arial, sans- serif'
					}}
				/>
			</Flex>
		</div>
	);
};

Section.propTypes = {
	title: PropTypes.string.isRequired,
	text: PropTypes.string.isRequired,
	link: PropTypes.string.isRequired,
	button: PropTypes.string.isRequired,
	icon: PropTypes.string.isRequired
};
