import {DataTable} from 'primereact/datatable';
import React, {useEffect, useState} from 'react';

import {Column} from 'primereact/column';

import {Ripple} from 'primereact/ripple';

import arrow_up from '../../../../src/assets/iconUp.png';
import arrow_down from '../../../../src/assets/iconDown.png';
import {classNames} from 'primereact/utils';
import {showErrors} from '../../../common/util';
import {
	getDataGridData,
	getDataGridValues,
	getFilterFlags,
	saveFilter
} from '../../../services/data-grid-service';
import {FacilityFilter} from './facility-filter/facility-filter';
import {Box} from '@chakra-ui/react';
import {Button} from '../../../components/ux-standard/button/button';
import {styles} from './data-grid.style';
import {Spinner} from '../../../components/utilities/spinner';
import {ClaimDetailsModal} from '../../../components/template/claimDetailsModal';
import {Dropdown} from 'primereact/dropdown';
import {SaveFilterModal} from '../../../components/save-filter/saveFilterModal';
import {success} from '../../../components/ux-standard/toast/toast-service';
export const DataGrid = () => {
	const [parsGridColumn, setParsGridCloumn] = useState([]);
	const selectedFilterKeys = {};
	const [parsData, setParsData] = useState([]);
	const [isLoading, setIsLoading] = useState(true);
	const [saveFilterOpen, setSaveFilterOpen] = useState(false);

	const [totalRecords, setTotalRecords] = useState(0);
	const [sortCoulmnType, setSortCoumnType] = useState('');
	const [sortCaolumnValue, setSortColumnValue] = useState('');
	const [dateValue, setDateValue] = useState('');

	const [currentPage, setCurrentPage] = useState(1);
	const [first, setFirst] = useState(0);
	const [rows, setRows] = useState(50);
	const [pageSize, setPageSize] = useState(50);

	const [filterName, setFilterName] = useState('');

	const [transformObject, setTransformObject] = useState({});
	const [note, setNote] = useState('');
	const [filterStartDate, setFilterStartDate] = useState('');
	const [filterEndtDate, setFilterEndtDate] = useState('');
	const [isOpen, setIsOpen] = useState(false);
	const [sortDataFields, setSortDataFields] = useState([]);
	const [sortingArray, setSortingArray] = useState([]);
	const [filterFlags, setFilterFlags] = useState([]);
	const [clickCount, setClickCount] = useState(0);
	const [, setSelectedFlagData] = useState([]);

	const itemsToAddforSorting = [
		{type: '1', value: [{dir: 'asc', field: ''}]},
		{type: '2', value: [{dir: 'desc', field: ''}]},
		{type: '3', value: []}
	];

	const [, setLatestClickIndex] = useState(-1);
	const [claimUid, setClaimUid] = useState({});
	const [selectedColumn, setSelectedColumn] = useState(null);

	useEffect(() => {
		(async () => {
			try {
				const payload = {
					gridParams: {pageSize: pageSize, pageNumber: currentPage},
					filterParams: selectedFilterKeys,
					take: pageSize,
					skip: currentPage === 1 ? 0 : currentPage * pageSize,

					page: currentPage,
					pageSize: pageSize
				};
				const [columnResponse, datatableResponse, filterFlagsResponse] =
					await Promise.all([
						getDataGridValues(),

						getDataGridData(payload),
						getFilterFlags()
					]);

				let newResponse = columnResponse.map(item => {
					return {...item, field: item.name, sortSelected: false};
				});
				let newResponseFlag = filterFlagsResponse.map((item, index) => {
					return {...item, id: index + 1, label: item.name, selected: false};
				});

				setParsData(datatableResponse.content);
				setTotalRecords(datatableResponse.total);
				setParsGridCloumn(newResponse);
				setFilterFlags(newResponseFlag);
			} catch (error) {
				showErrors(error);
			} finally {
				setIsLoading(false);
			}
		})();
	}, []);

	useEffect(() => {
		onApiCall(transformObject, note, filterStartDate, filterEndtDate, dateValue);
	}, [currentPage, sortingArray, pageSize, rows, first]);

	const onSetFilters = (
		selectedFilters,
		note,
		startDateVal,
		endDateVal,
		dateTypeVal,
		selectedFlagData
	) => {
		const transformedObject = {};

		for (const key in selectedFilters) {
			if (Array.isArray(selectedFilters[key])) {
				transformedObject[key] = selectedFilters[key]
					.map(value => {
						if (value.selected === true) {
							return value.label;
						}
						return null;
					})
					.filter(Boolean);
			}
		}

		const result = filterationAvailablity(transformedObject);

		if (!result) {
			setFilterEndtDate(endDateVal);
			setFilterStartDate(startDateVal);
			setNote(note);
			setDateValue(dateTypeVal);
			setTransformObject(transformedObject);
			setSelectedFlagData(selectedFlagData);

			onApiCall(
				transformedObject,
				note,
				startDateVal,
				endDateVal,
				dateTypeVal,
				selectedFlagData
			);
		} else {
			setFilterEndtDate(endDateVal);
			setFilterStartDate(startDateVal);
			setNote(note);
			setDateValue(dateTypeVal);
			setTransformObject(transformedObject);
			setSelectedFlagData(selectedFlagData);
			onApiCall(
				transformedObject,
				note,
				startDateVal,
				endDateVal,
				dateTypeVal,
				selectedFlagData
			);
		}
	};

	const filterationAvailablity = obj => {
		return Object.values(obj).every(
			array => Array.isArray(array) && array.length === 0
		);
	};

	const onCLickLastPage = async () => {
		setFirst(totalRecords - rows);
		await setCurrentPage(Math.floor(first / rows) + 1);
	};
	const firstPageTemplate = () => {
		return (
			<button
				onClick={() => {
					setFirst(0);
					setCurrentPage(1);
				}}
				className={`p-paginator-first p-paginator-element p-link`}
				tabIndex={0}
				aria-disabled={first === 0}
				onKeyDown={event => event.key === 'Enter' && setFirst(0)}
			>
				{'First'}
			</button>
		);
	};

	const lastPageTemplate = () => {
		const lastPage = Math.floor(first / rows) + 1;
		setCurrentPage(lastPage);

		return (
			<button
				onClick={onCLickLastPage}
				className={`p-paginator-last p-paginator-element p-link`}
				tabIndex={0}
				aria-disabled={first >= totalRecords - rows}
				onKeyDown={event =>
					event.key === 'Enter' && setFirst(totalRecords - rows)
				}
			>
				{'Last'}
			</button>
		);
	};

	const onApiCall = async (
		transformData,
		note,
		startDateVal,
		endDateVal,
		dateTypeVal,
		selectedFlagData
	) => {
		try {
			setIsLoading(true);
			const transObj = transformData;
			const skipPageData = currentPage - 1;
			const payload = {
				gridParams: {
					pageSize: pageSize,
					pageNumber: currentPage,
					sortColumn: sortCaolumnValue,
					sortDirection: sortCoulmnType
				},
				filterParams: transObj,
				take: pageSize,
				skip: currentPage === 1 ? 0 : skipPageData * pageSize,
				page: currentPage,
				notesText: '',
				pageSize: pageSize,
				date: dateTypeVal,
				startDate: startDateVal,
				endDate: endDateVal,
				sort: sortingArray,
				flags: selectedFlagData
			};

			const datatableResponse = await getDataGridData(payload);
			setTotalRecords(datatableResponse.total);
			setParsData(datatableResponse.content);
		} catch (error) {
			showErrors(error);
		} finally {
			setIsLoading(false);
		}
	};

	const onSaveSelectedFilterName = async val => {
		setIsLoading(true);
		let filterOptions = {
			filterOptions: [
				{
					filterID: 0,
					filterName: val,
					filterCategory: 'string',
					typeId: 0,
					setdefault: 0,
					msId: 'string',
					createDate: '2024-01-15T11:39:47.431Z',
					filterOptions: 'string',
					dateType: 'ID Date',
					startDate: '2023-06-29T18:30:00.000Z',
					endDate: '2024-01-09T18:30:00.000Z',
					flags: [
						{
							filterID: 0,
							databaseColumn: 'string',
							name: val,
							value: true
						}
					]
				}
			],
			flagOptions: [
				{
					filterID: 0,
					databaseColumn: 'string',
					name: 'string',
					value: true
				}
			],
			claimNote: 'string'
		};
		await saveFilter(filterOptions);
		success(['Filter Name Saved Successfully']);
		setIsLoading(false);
		setSaveFilterOpen(false);
		setFilterName('');
	};

	const handlePageChange = event => {
		setRows(event.rows);
		setFirst(event.first);
		const newPage = Math.floor(event.first / event.rows) + 1;
		setIsLoading(true); // Calculate the new page number

		setCurrentPage(newPage);
	};

	const onHeaderClick = (event, col) => {
		setClickCount(clickCount + 1);

		let sortingArray = [];
		let sortType = '';
		const itemToAddIndex = clickCount % itemsToAddforSorting.length;
		if (itemToAddIndex === 0) {
			setIsLoading(true);
			sortingArray = [{dir: 'asc', field: col.field}];
			sortType = 'asc';
		} else if (itemToAddIndex === 1) {
			setIsLoading(true);
			sortingArray = [{dir: 'desc', field: col.field}];
			sortType = 'desc';
		} else if (itemToAddIndex === 2) {
			setIsLoading(true);
			sortingArray = [];
			sortType = '';
		}

		setSortDataFields(() => [
			{
				...itemsToAddforSorting[itemToAddIndex],
				latestClick: clickCount
			}
		]);

		setSortingArray(sortingArray);
		setSortColumnValue(col.field);
		setSortCoumnType(sortType);

		setLatestClickIndex(itemToAddIndex);
		handleColumnClick(col, sortType);
	};

	const onOpenClaim = event => {
		setClaimUid(event);
		setIsOpen(true);
	};

	const customPaginator = {
		layout: 'FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown CurrentPageReport',
		style: '20%',
		FirstPageLink: firstPageTemplate,
		LastPageLink: lastPageTemplate,
		PrevPageLink: options => {
			return (
				<button
					type="button"
					style={{marginRight: '10px'}}
					className={classNames(options.className, 'border-round')}
					onClick={options.onClick}
					disabled={options.disabled}
				></button>
			);
		},
		NextPageLink: options => {
			return (
				<button
					type="button"
					style={{marginLeft: '10px'}}
					className={classNames(options.className, 'border-round')}
					onClick={options.onClick}
					disabled={options.disabled}
				>
					<span className="p-3">{'Next'}</span>
				</button>
			);
		},
		PageLinks: options => {
			if (
				(options.view.startPage === options.page &&
					options.view.startPage !== 0) ||
				(options.view.endPage === options.page &&
					options.page + 1 !== options.totalPages)
			) {
				const className = classNames(options.className, {'p-disabled': true});

				return (
					<span className={className} style={{userSelect: 'none'}}>
						{'...'}
					</span>
				);
			}

			return (
				<button
					type="button"
					className={options.className}
					onClick={options.onClick}
				>
					{options.page + 1}
					<Ripple />
				</button>
			);
		},
		RowsPerPageDropdown: options => {
			const dropdownOptions = [
				{label: 50, value: 50},
				{label: 100, value: 100},
				{label: 200, value: 200},
				{label: 500, value: 500}
			];

			return (
				<Dropdown
					value={options.value}
					options={dropdownOptions}
					onChange={event => {
						setRows(event.value);
						setFirst(0);
						setPageSize(event.value);
					}}
				/>
			);
		},
		CurrentPageReport: options => {
			return (
				<span
					style={{
						color: 'var(--text-color)',
						userSelect: 'none',
						textAlign: 'center',
						marginLeft: '20px'
					}}
				>
					{options.first} {'-'} {options.last} {'of'} {`${totalRecords} items`}
				</span>
			);
		}
	};

	const renderIcon = column => {
		let columnImageType = null;
		if (selectedColumn.field === column.field && selectedColumn.imageType === 'asc') {
			columnImageType = (
				<img style={styles.arrowImage} src={arrow_up} alt="not-found" />
			);
		} else if (
			selectedColumn.field === column.field &&
			selectedColumn.imageType === 'desc'
		) {
			columnImageType = (
				<img style={styles.arrowImage} src={arrow_down} alt="not-found" />
			);
		} else if (
			selectedColumn.field === column.field &&
			selectedColumn.imageType === ''
		) {
			columnImageType = null;
		} else {
			columnImageType = null;
		}

		return columnImageType;
	};

	const handleColumnClick = (column, sortType) => {
		let imageType = sortType;
		setSelectedColumn(
			column.field === selectedColumn
				? null
				: {field: column.field, imageType: imageType}
		);
	};

	const onGetSaveFilterName = val => {
		setIsLoading(true);
		onSaveSelectedFilterName(val);
	};

	return (
		<div>
			<div>
				<FacilityFilter
					flagsDataRes={filterFlags}
					onSaveFlag={() => setSaveFilterOpen(true)}
					inputValue={(data, note) => onSetFilters(data, note)}
					onFilterSection={(
						data,
						note,
						startDateVal,
						endDateVal,
						dateTypeVal,
						selectedFlagData
					) =>
						onSetFilters(
							data,
							note,
							startDateVal,
							endDateVal,
							dateTypeVal,
							selectedFlagData
						)
					}
				/>
			</div>

			<div>
				<Box style={styles.btnConatiner}>
					<Button styles={styles.exportBtn} label={'Export Data Grid'} />
					<Button styles={styles.exportBtn} label={'Export Detail Data'} />
				</Box>
				<Box style={{marginBottom: '50px'}}>
					<DataTable
						sortField="dataSource"
						lazy={true}
						rows={rows}
						first={first}
						tableStyle={{minWidth: '730px', marginTop: '5px'}}
						value={parsData}
						size="small"
						stripedRows
						paginator={true}
						pageLinkSize={10}
						totalRecords={totalRecords}
						onPage={handlePageChange}
						showGridlines
						paginatorTemplate={customPaginator}
						sorting
						sortMode="single"
					>
						<Column
							style={{width: '10px'}}
							header=""
							body={event => (
								<i
									onClick={() => onOpenClaim(event)}
									className="pi pi-star"
								/>
							)}
						/>
						{parsGridColumn.map(col => (
							<Column
								key={col.field}
								header={() => (
									<div
										style={{
											display: 'flex',
											flexDirection: 'row',
											justifyContent: 'center',
											alignItems: 'center'
										}}
									>
										<span
											onClick={event => onHeaderClick(event, col)}
										>
											{col.displayName}
										</span>
										{sortDataFields.length > 0
											? renderIcon(col)
											: null}
									</div>
								)}
								field={col.field}
								style={styles.column}
								body={col.body}
								headerStyle={col.width}
								bodyStyle={col.width}
								orderBy={col.orderBy}
							/>
						))}
					</DataTable>
				</Box>
			</div>

			<ClaimDetailsModal
				claim={claimUid}
				isOpen={isOpen}
				onClose={() => setIsOpen(false)}
			/>
			<SaveFilterModal
				selectedFilterName={val => onGetSaveFilterName(val)}
				filterName={name => setFilterName(name)}
				filterNameValue={filterName}
				isOpen={saveFilterOpen}
				onClose={() => setSaveFilterOpen(false)}
			/>
			{isLoading && <Spinner data-testid="spinner" />}
		</div>
	);
};
