import React from 'react';
import {Flex} from '@chakra-ui/react';
import {Section} from './display-section';
import PropTypes from 'prop-types';
import {styles} from './display.style';

export const DisplayContent = ({sections}) => {
	return (
		<Flex data-testid="display-content" sx={styles.container}>
			{sections.map((item, index) => (
				<Section
					key={index}
					title={item.title}
					text={item.text}
					link={item.link}
					button={item.button}
					icon={item.icon}
				/>
			))}
		</Flex>
	);
};

const sectionsData = {
	title: PropTypes.string.isRequired,
	text: PropTypes.string.isRequired,
	link: PropTypes.string.isRequired,
	button: PropTypes.string.isRequired,
	icon: PropTypes.string.isRequired
};

DisplayContent.propTypes = {
	sections: PropTypes.arrayOf(PropTypes.shape(sectionsData)).isRequired
};
