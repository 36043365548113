const baseStyles = {
	fontFamily: 'Helvetica Neue, Helvetica, Arial, sans- serif',
	color: '#63666A'
};

export const styles = {
	subtitle: {
		fontSize: '13px',
		fontFamily: baseStyles.fontFamily,
		fontType: 'bold',
		color: '#63666A',
		paddingBottom: '2px'
	},
	display: {
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'space-between',
		width: '230px',
		height: '60px'
	},
	flagContainer: {
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'space-between',
		width: '260px',
		height: '120px',
		backgroundColor: '#efefee',
		borderRadius: '7px',
		boxShadow: '5px 8px 8px #888888'
	},
	menuButtonText: {
		fontSize: '13px',
		fontFamily: baseStyles.fontFamily,
		color: baseStyles.color,
		fontWeight: 'lighter'
	},
	menuButtonIcon: {
		marginLeft: '2px',
		marginTop: '2px',
		width: '12px',
		height: '12px'
	},
	selectedMenuOptions: {
		backgroundColor: '#dfdfdd'
	},
	tabs: {
		_selected: {bg: 'gray.500', color: 'white'},
		bg: 'lightgrey',
		color: 'gray',
		borderRadius: 'md',
		mr: '3px'
	}
};
