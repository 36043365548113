import _ from 'lodash';
import {useStyleConfig} from '@chakra-ui/react';

export const useChakraStyleMerge = ({
	themeKey = '',
	styleConfig,
	variant,
	size,
	styles
}) => {
	// This must be ahead of the completeOverride check because it is a hook and must be called in the same order each render
	const themeStyleConfig = useStyleConfig(themeKey, {
		size,
		variant
	});

	const overrideStyleConfig = useStyleConfig('', {
		size,
		variant,
		styleConfig
	});

	if (styles && styles.completeOverride) {
		return styles;
	}

	return _.merge(themeStyleConfig, overrideStyleConfig, styles);
};

// Commented for future use.
/*
export const useChakraMultiStyleMerge = ({
	themeKey = '',
	styleConfig,
	variant,
	size,
	styles
}) => {
	// This must be ahead of the completeOverride check because it is a hook and must be called in the same order each render

	const themeStyleConfig = useMultiStyleConfig(themeKey, {
		size,
		variant
	});

	const overrideStyleConfig = useMultiStyleConfig('', {
		size,
		variant,
		styleConfig
	});

	if (styles && styles.completeOverride) {
		return styles;
	}

	return _.merge(themeStyleConfig, overrideStyleConfig, styles);
};
*/
