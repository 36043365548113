import React from 'react';
import {PropTypes} from 'prop-types';
import {ErrorDisplay} from './error-display';
import {stringEquals} from '../../../common/util';

export class ErrorBoundary extends React.Component {
	static propTypes = {
		children: PropTypes.node
	};

	constructor(props) {
		super(props);
		this.state = {hasError: false, error: null};
	}

	static getDerivedStateFromError(error) {
		// Update state so the next render will show the fallback UI.
		return {hasError: true, error};
	}

	async componentDidCatch(error) {
		await console.log(`${error.name}: ${error.message}`);
	}

	render() {
		const {error, hasError} = this.state;
		if (hasError) {
			// You can render any custom fallback UI
			return (
				<ErrorDisplay
					errorMessage={
						stringEquals(error?.name, 'ChunkLoadError')
							? 'A new version of the app is available, please refresh your browser to see the latest content.'
							: 'Please try refreshing the page. If the problem continues please contact your system administrator.'
					}
				/>
			);
		}

		return this.props.children;
	}
}
