import React, {useState, useEffect} from 'react';
import {useForm} from 'react-hook-form';
import {
	Flex,
	Box,
	Checkbox,
	CheckboxGroup,
	Stack,
	Grid,
	Text,
	Tabs,
	TabList,
	TabPanels,
	Tab,
	Image,
	TabPanel,
	Radio,
	RadioGroup,
	Tooltip,
	CloseButton
} from '@chakra-ui/react';
import {DeleteFilterDialog} from '../../../components/ux-standard/alert-dialog/alert-dailog';
import {Calendar} from '../../../components/ux-standard/calendar/calendar';
import {styles} from './filter-options.style';
import {InputText} from '../../../components/ux-standard/input-text/input-text';
import save from '../../../assets/images/save.svg';
import {showErrors} from '../../../common/util';
import AddFilter from '../../../assets/images/addfilter.png';
import {success, error} from '../../../components/ux-standard/toast/toast-service';
import {FilterComponent} from './filter-component';
import {FilterCheckboxGroup} from './filter-options-checkbox-group';
import {
	getClientType,
	getClientName,
	getPlatform,
	getVendorType,
	getVendorName,
	getLineOfBusiness,
	getAuditCategory,
	getSubCategory,
	getDates,
	getFlags
} from '../../../services/filter-options-service';
import {
	deleteUserFilter,
	getUserFilterById,
	setAsDefaultFilter,
	getUserFilterDates,
	getUserFilters,
	getUserFilterFlags,
	updateUserFilter,
	getUserFilterByName
} from '../../../services/user-filter-service';

export const Filter = () => {
	const {setValue, reset} = useForm();
	const [filters, setFilters] = useState([]);
	const [clientType, setClientType] = useState([]);
	const [clientName, setClientName] = useState([]);
	const [platform, setPlatform] = useState([]);
	const [vendorType, setVendorType] = useState([]);
	const [vendorName, setVendorName] = useState([]);
	const [lineOfBusiness, setLineOfBusiness] = useState([]);
	const [auditCategory, setAuditCategory] = useState([]);
	const [subCategory, setSubCategory] = useState([]);
	const [dates, setDates] = useState([]);
	const [flags, setFlags] = useState([]);
	const [filterName, setFilterName] = useState('');
	const [defaultFilterName, setDefaultFilterName] = useState('');
	const [isEditing, setIsEditing] = useState(false);
	const [isFilterModalVisible, setIsFilterModalVisible] = useState(false);
	const [forceUpdate, setForceUpdate] = useState(false);
	const [defaultChecked, setDefaultChecked] = useState(false);
	const [clientTypeValue, setClientTypeValue] = useState(false);
	const [clearCheckBoxes, setClearCheckBoxes] = useState(false);
	const [deleteFilterId, setDeleteFilterId] = useState(null);
	const [claimNoteSearch, setClaimNoteSearch] = useState('');
	const [preFilters, setPreFilters] = useState([]);
	const [selectedDate, setSelectedDate] = useState({
		dateType: '',
		startDate: new Date(),
		endDate: new Date()
	});
	const [selectedFlags, setSelectedFlags] = useState([]);
	const [editingFilterId, setEditingFilterId] = useState(null);
	const filterOptions = [];
	const flagOptions = [];
	const claimNote = '';
	const [requestData, setRequestData] = useState({
		filterOptions: filterOptions,
		flagOptions: flagOptions,
		claimNote: claimNote
	});
	const [updatedFormValues, setUpdatedFormValues] = useState({});
	const [alertModalVisible, setAlertModalVisible] = useState(false);
	const [searchFilterName, setSearchFilterName] = useState('');
	const [isOpen, setIsOpen] = useState(false);

	const filteredFilters =
		searchFilterName === ''
			? filters
			: filters.filter(filter =>
					filter.filtername
						.toLowerCase()
						.includes(searchFilterName.toLowerCase())
			  );

	const handleFilterChange = newFilters => {
		const newSelectedFlags = {};
		newFilters.forEach(flagName => {
			newSelectedFlags[flagName] = selectedFlags[flagName] || false;
		});
		setSelectedFlags(newSelectedFlags);
	};

	const handleDateChange = newDates => {
		setSelectedDate(prev => ({...prev, dateType: newDates}));
	};

	const handleFlagChange = (flagName, newValue) => {
		setSelectedFlags(prevSelectedFlags => ({
			...prevSelectedFlags,
			[flagName]: newValue
		}));
	};

	useEffect(() => {
		setForceUpdate(!forceUpdate);
	}, [filters]);

	useEffect(() => {
		(async () => {
			try {
				const [
					clientType,
					clientName,
					platform,
					vendorType,
					vendorName,
					lineOfBusiness,
					auditCategory,
					subCategory,
					dates,
					flags,
					userFilterById
				] = await Promise.all([
					getClientType(),
					getClientName(),
					getPlatform(),
					getVendorType(),
					getVendorName(),
					getLineOfBusiness(),
					getAuditCategory(),
					getSubCategory(),
					getDates(),
					getFlags(),
					getUserFilterById()
				]);

				setClientType(clientType);
				setClientName(clientName);
				setPlatform(platform);
				setVendorType(vendorType);
				setVendorName(vendorName);
				setLineOfBusiness(lineOfBusiness);
				setAuditCategory(auditCategory);
				setSubCategory(subCategory);
				setDates(dates);
				setFlags(flags);

				if (userFilterById != null && userFilterById.length > 0) {
					setFilters(userFilterById);
					if (userFilterById[0].defaultfilter) {
						setDefaultFilterName(userFilterById[0].filtername);
					}
				}
			} catch (error) {
				showErrors(error);
			}
		})();
	}, []);

	useEffect(() => {
		(async () => {
			try {
				const filterID = editingFilterId || 0;
				if (
					requestData.filterOptions.length > 0 ||
					requestData.flagOptions.length > 0
				) {
					await updateUserFilter(requestData, filterID);
					clearBoxes();
					success('Filter saved successfully.');
					var response = await getUserFilterById();
					if (response.length > 0) {
						if (response[0].defaultfilter) {
							setDefaultFilterName(response[0].filtername);
						} else {
							setDefaultFilterName(null);
						}
					}
					setIsFilterModalVisible(false);
					setFilters(response);
					setEditingFilterId(null);
				}
			} catch (err) {
				error('Error saving filters, dates and flags');
				showErrors(err);
			}
		})();
	}, [requestData]);

	const onChange = event => {
		const filterName = event.target.value;
		setFilterName(filterName);
	};

	const onUpdateFormValues = (name, values) => {
		setUpdatedFormValues(prevState => ({
			...prevState,
			[name]: values
		}));
	};

	const clearBoxes = () => {
		setPreFilters([]);
		setSelectedDate({});
		setSelectedFlags([]);
		setClearCheckBoxes(true);
		setClaimNoteSearch('');
		setDefaultChecked(false);
	};

	const handleDeleteCancel = () => {
		setIsOpen(false);
		setAlertModalVisible(false);
	};

	const handleDeleteConfirm = async () => {
		try {
			setAlertModalVisible(false);
			await deleteUserFilter(deleteFilterId);
			const response = await getUserFilterById();
			if (response != null && response.length > 0) {
				if (response[0].defaultfilter) {
					setDefaultFilterName(response[0].filtername);
				} else {
					setDefaultFilterName(null);
				}
			}
			success('Filter was deleted successfully');
			setFilters(response);
		} catch (error) {
			showErrors(error);
		}
	};

	const handleDelete = async id => {
		setAlertModalVisible(true);
		setIsOpen(true);
		setDeleteFilterId(id);
	};

	const handleEdit = async id => {
		try {
			setIsEditing(true);
			setEditingFilterId(id);

			// Make the API calls in parallel
			const [filters, dates, flags] = await Promise.all([
				getUserFilters(id),
				getUserFilterDates(id),
				getUserFilterFlags(id)
			]);

			const prevFilters = filters.reduce((accumulator, filter) => {
				if (filter.filtertype !== '') {
					if (!accumulator[filter.filtertype]) {
						accumulator[filter.filtertype] = [];
					}
					accumulator[filter.filtertype].push(filter.filtername);
				} else {
					setDefaultChecked(filter.defaultfilter);
					setFilterName(filter.filtername);
				}
				return accumulator;
			}, {});
			setPreFilters(prevFilters);
			const claimNoteValue =
				prevFilters['Claim Note'] && prevFilters['Claim Note'].length > 0
					? prevFilters['Claim Note'][0]
					: '';
			setClaimNoteSearch(claimNoteValue);
			setSelectedDate({
				dateType: dates[0].dateType,
				startDate: new Date(dates[0].startDate),
				endDate: new Date(dates[0].endDate)
			});

			const prevFlags = flags.reduce((accumulator, flag) => {
				accumulator[flag.flagName] = flag.flagValue === 1 ? 'Yes' : 'No';
				return accumulator;
			}, {});
			setSelectedFlags(prevFlags);
		} catch (error) {
			showErrors(error);
		}
	};

	const handleCancelEditing = async () => {
		setIsEditing(false);
		clearBoxes();
	};

	const handleSetAsDefault = async id => {
		try {
			await setAsDefaultFilter(id);
			var response = await getUserFilterById();
			if (response != null && response.length > 0) {
				setFilters(response);
				if (response[0].defaultfilter) {
					setDefaultFilterName(response[0].filtername);
				}
			}
			success('Filter was set as default successfully.');
		} catch (error) {
			showErrors(error);
		}
	};

	const handleSave = async () => {
		//check if filter already exists and isEditing is false
		if (isEditing === false) {
			const result = await getUserFilterByName(filterName.toLowerCase());
			if (result != null && result.length > 0) {
				error('Filter name already exists');
				return;
			}
		}
		filterOptions.push({
			filterName: filterName,
			setdefault: defaultChecked === true ? 1 : 0,
			typeId: 1,
			filterCategory: '',
			msId: '',
			createDate: new Date(),
			filterOptions: '',
			dateType: selectedDate.dateType,
			startDate: selectedDate.startDate,
			endDate: selectedDate.endDate,
			flags: []
		});
		Object.entries(updatedFormValues).forEach(([key, value]) => {
			if (
				[
					'Line of Business',
					'Platform',
					'Audit Category',
					'Client Name',
					'Client Type',
					'Sub Category',
					'Vendor Name',
					'Vendor Type'
				].includes(key)
			) {
				if (Array.isArray(value)) {
					value.forEach(optionValue => {
						filterOptions.push({
							filterName: filterName,
							setdefault: defaultChecked === true ? 1 : 0,
							typeId: 1,
							filterCategory: key,
							msId: '',
							createDate: new Date(),
							filterOptions: optionValue,
							dateType: selectedDate.dateType,
							startDate: selectedDate.startDate,
							endDate: selectedDate.endDate,
							flags: []
						});
					});
				}
			}
		});

		Object.entries(selectedFlags).forEach(([flagName, flagValue]) => {
			const flagOption = flags.find(flag => flag.name === flagName);
			flagOptions.push({
				filterID: editingFilterId != null ? editingFilterId : 0,
				name: flagName,
				databaseColumn: flagOption.databaseColumn,
				value: flagValue === 'Yes' ? true : false
			});
		});
		setRequestData({filterOptions, flagOptions, claimNote: claimNoteSearch});
		setIsEditing(false);
	};

	return (
		<div>
			{alertModalVisible === true ? (
				<DeleteFilterDialog
					isOpen={isOpen}
					onClose={handleDeleteCancel}
					onConfirm={handleDeleteConfirm}
				/>
			) : null}
			<div style={{maxHeight: '700px', overflow: 'scroll', paddingBottom: '50px'}}>
				<Flex direction="column" flexGrow={1}>
					<Flex w="100%" h="15%" alignItems="center" justifyContent="center">
						<Box w="5%"></Box>
						<Box w="40%" textAlign="center">
							<Text sx={styles.title}>{'Filter Options'}</Text>
							<Text sx={styles.subtitle}>{defaultFilterName}</Text>
						</Box>
					</Flex>
					<Flex alignItems="center" justifyContent="flex-end">
						<Checkbox
							onChange={() => setDefaultChecked(!defaultChecked)}
							key="default"
							size="sm"
							value={defaultChecked}
							isChecked={defaultChecked}
							colorScheme="orange"
						>
							{'Set as default'}
						</Checkbox>
					</Flex>

					<Flex w="100%" h="85%" flexGrow={1}>
						<Box w={{base: '250px', md: '20%'}}>
							<Flex
								display="flex"
								flexDirection="column"
								flexGrow="1"
								alignItems={'flex-end'}
							>
								<Tooltip hasArrow label="Add new filter" placement="top">
									<Image
										src={AddFilter}
										tooltip="Add Filter"
										cursor="pointer"
										onClick={() => {
											reset();
											setIsFilterModalVisible(true);
										}}
									/>
								</Tooltip>
								<InputText
									name="searchFilter"
									onChange={name =>
										setSearchFilterName(name.target.value)
									}
									placeholder="Search Filter"
									borderRadius="2px"
									size="md"
									type="text"
									width="250px"
									marginLeft="20px"
								/>

								{isFilterModalVisible && (
									<div style={{paddingTop: '5px'}}>
										{
											<Flex sx={styles.display}>
												<Tooltip
													hasArrow
													label="Save Filter"
													placement="top"
												>
													<Image
														src={save}
														style={{cursor: 'pointer'}}
														onClick={handleSave}
													/>
												</Tooltip>
												<div>
													<Text
														textAlign="center"
														sx={styles.subtitle}
													>
														{'*Filter Name'}
													</Text>
													<InputText
														name="filterName"
														type="text"
														placeholder="Enter a filter name"
														onChange={onChange}
														required
														width="140px"
														borderRadius="3px"
														defaultValue=""
													/>
												</div>
												<CloseButton
													onClick={() => {
														clearBoxes();
														setIsFilterModalVisible(false);
													}}
												/>
											</Flex>
										}
									</div>
								)}
								<div
									style={{
										paddingTop: '5px',
										maxHeight: '600px',
										overflowY: 'scroll'
									}}
								>
									{filteredFilters.map(filter => {
										return (
											<FilterComponent
												key={filter.filterid}
												filtername={filter.filtername}
												filterid={filter.filterid}
												handleDelete={handleDelete}
												handleSetAsDefault={handleSetAsDefault}
												handleSave={handleSave}
												handleEdit={handleEdit}
												handleCancelEditing={handleCancelEditing}
												isEditing={isEditing}
												isDefaultFilter={filter.defaultfilter}
												editingFilterId={editingFilterId}
											/>
										);
									})}
								</div>
							</Flex>
						</Box>
						<Box
							w={{base: 'calc(100% - 150px)', md: '80%'}}
							//paddingBottom={'50px'}
						>
							<Tabs variant={'unstyled'} size="sm">
								<TabList spacing={8}>
									<Tab {...styles.tabs}>{'Filters'}</Tab>
									<Tab {...styles.tabs}>{'Dates'}</Tab>
									<Tab {...styles.tabs}>{'Flags'}</Tab>
								</TabList>
								<TabPanels>
									<TabPanel>
										<Flex
											justifyContent="space-between"
											width="100%"
											style={{
												borderRadius: '4px',
												position: 'relative',
												left: '0%'
											}}
										>
											<Box flex="1" p="4">
												<FilterCheckboxGroup
													options={clientType}
													name="Client Type"
													id="clientType"
													setClientTypeValue={
														setClientTypeValue
													}
													preFilters={
														preFilters !== []
															? preFilters['Client Type']
															: []
													}
													onUpdateFormValues={
														onUpdateFormValues
													}
													clearCheckBoxes={clearCheckBoxes}
												/>
												<FilterCheckboxGroup
													options={
														clientTypeValue === true
															? []
															: clientName
													}
													name="Client Name"
													id="clientName"
													setClientTypeValue={
														setClientTypeValue
													}
													preFilters={
														preFilters !== []
															? preFilters['Client Name']
															: []
													}
													onUpdateFormValues={
														onUpdateFormValues
													}
													clearCheckBoxes={clearCheckBoxes}
												/>
												<FilterCheckboxGroup
													options={
														clientTypeValue === true
															? []
															: platform
													}
													name="Platform"
													id="platform"
													setClientTypeValue={
														setClientTypeValue
													}
													preFilters={
														preFilters !== []
															? preFilters['Platform']
															: []
													}
													onUpdateFormValues={
														onUpdateFormValues
													}
													clearCheckBoxes={clearCheckBoxes}
												/>
											</Box>
											<Box flex="2" p="4">
												<FilterCheckboxGroup
													options={
														clientTypeValue === true
															? []
															: vendorType
													}
													name="Vendor Type"
													id="vendorType"
													setClientTypeValue={
														setClientTypeValue
													}
													preFilters={
														preFilters !== []
															? preFilters['Vendor Type']
															: []
													}
													onUpdateFormValues={
														onUpdateFormValues
													}
													clearCheckBoxes={clearCheckBoxes}
												/>
												<FilterCheckboxGroup
													options={
														clientTypeValue === true
															? []
															: vendorName
													}
													name="Vendor Name"
													id="vendorName"
													setClientTypeValue={
														setClientTypeValue
													}
													preFilters={
														preFilters !== []
															? preFilters['Vendor Name']
															: []
													}
													onUpdateFormValues={
														onUpdateFormValues
													}
													clearCheckBoxes={clearCheckBoxes}
												/>
											</Box>
											<Box flex="2" p="4">
												<FilterCheckboxGroup
													options={
														clientTypeValue === true
															? []
															: lineOfBusiness
													}
													name="Line of Business"
													id="lineOfBusiness"
													setClientTypeValue={
														setClientTypeValue
													}
													preFilters={
														preFilters !== []
															? preFilters[
																	'Line of Business'
															  ]
															: []
													}
													onUpdateFormValues={
														onUpdateFormValues
													}
													clearCheckBoxes={clearCheckBoxes}
												/>
												<FilterCheckboxGroup
													options={
														clientTypeValue === true
															? []
															: auditCategory
													}
													name="Audit Category"
													id="auditCategory"
													setClientTypeValue={
														setClientTypeValue
													}
													preFilters={
														preFilters !== []
															? preFilters['Audit Category']
															: []
													}
													onUpdateFormValues={
														onUpdateFormValues
													}
													clearCheckBoxes={clearCheckBoxes}
												/>
												<FilterCheckboxGroup
													options={
														clientTypeValue === true
															? []
															: subCategory
													}
													name="Sub Category"
													id="subCategory"
													setClientTypeValue={
														setClientTypeValue
													}
													preFilters={
														preFilters !== []
															? preFilters['Sub Category']
															: []
													}
													onUpdateFormValues={
														onUpdateFormValues
													}
													clearCheckBoxes={clearCheckBoxes}
												/>
												<strong>
													<Text sx={styles.subtitle}>
														{'Claim Note Search'}
													</Text>
												</strong>
												<InputText
													name="claimNoteSearch"
													value={claimNoteSearch}
													onChange={message =>
														setClaimNoteSearch(
															message.target.value
														)
													}
													type="text"
													placeholder=""
													width="260px"
													height="30px"
													borderRadius="3px"
												/>
											</Box>
										</Flex>
									</TabPanel>
									<TabPanel>
										<Flex
											flex="1"
											borderRadius="4px"
											direction="column"
											width="230px"
											height="400px"
											paddingBottom="50px"
										>
											<RadioGroup
												onChange={value => {
													setValue(
														'dateType',
														value.toString()
													);
													handleDateChange(value.toString());
												}}
												value={selectedDate.dateType}
												colorScheme="orange"
												{...styles.radioOrCheckBoxGroup}
											>
												<Stack spacing={2} direction="column">
													{dates.map(date => (
														<Stack
															spacing={4}
															direction="column"
															key={date.name}
														>
															<Radio
																value={date.name}
																isChecked={
																	selectedDate.dateType ===
																	date.name
																}
															>
																{date.name}
															</Radio>
														</Stack>
													))}
													<Stack
														spacing={1}
														direction={'column'}
													>
														<Text sx={styles.subtitle}>
															{'Start Date'}
														</Text>
														<Calendar
															size="sm"
															value={selectedDate.startDate}
															onChange={date =>
																setSelectedDate({
																	...selectedDate,
																	startDate: date.value
																})
															}
															showIcon
															showButtonBar
														/>
														<Text sx={styles.subtitle}>
															{'End Date'}
														</Text>
														<Calendar
															size="small"
															value={selectedDate.endDate}
															onChange={date =>
																setSelectedDate({
																	...selectedDate,
																	endDate: date.value
																})
															}
															showIcon
															showButtonBar
														/>
													</Stack>
												</Stack>
											</RadioGroup>
										</Flex>
									</TabPanel>
									<TabPanel>
										<Box>
											<CheckboxGroup
												value={Object.keys(selectedFlags)}
												onChange={handleFilterChange}
												colorScheme="orange"
												{...styles.radioOrCheckBoxGroup}
											>
												{flags.map(flagOption => (
													<Grid
														templateColumns="1fr 1fr"
														key={flagOption.name}
													>
														<Checkbox
															value={flagOption.name}
															isChecked={
																selectedFlags[
																	flagOption.name
																]
															}
														>
															{flagOption.name}
														</Checkbox>
														<RadioGroup
															value={
																selectedFlags[
																	flagOption.name
																]
															}
															onChange={newValue =>
																handleFlagChange(
																	flagOption.name,
																	newValue
																)
															}
															colorScheme="orange"
															{...styles.radioOrCheckBoxGroup}
														>
															<Stack
																spacing={4}
																direction="row"
															>
																<Radio
																	value="Yes"
																	isChecked={
																		selectedFlags[
																			flagOption
																				.name
																		] === 'Yes'
																	}
																>
																	{'Yes'}
																</Radio>
																<Radio
																	value="No"
																	isChecked={
																		selectedFlags[
																			flagOption
																				.name
																		] === 'No'
																	}
																>
																	{'No'}
																</Radio>
															</Stack>
														</RadioGroup>
														<div
															style={{padding: '5px'}}
														></div>
													</Grid>
												))}
											</CheckboxGroup>
										</Box>
									</TabPanel>
								</TabPanels>
							</Tabs>
						</Box>
					</Flex>
				</Flex>
			</div>
		</div>
	);
};
