export const styles = {
	container: {
		display: 'flex',
		flexWrap: 'wrap',
		flexDirection: 'row',
		justifyContent: 'space-evenly'
	},
	display1: {
		display: 'flex',
		flexWrap: 'wrap',
		flexDirection: 'column',
		alignItems: 'center',
		maxwidth: '230px',
		height: '200px'
	},
	display2: {
		display: 'flex',
		flexWrap: 'wrap',
		flexDirection: 'column',
		alignItems: 'center',
		maxwidth: '230px',
		height: '100px'
	},
	img: {
		width: '40px',
		height: '40px'
	},
	title: {
		fontSize: '18px',
		color: 'grey',
		textAlign: 'center'
	},
	text: {
		fontSize: '0.9em',
		color: 'grey',
		textAlign: 'center',
		wordWrap: 'break-word',
		marginBottom: '2px',
		maxWidth: '350px'
	},
	btn: {
		background: 'black',
		color: 'white'
	}
};
