import React from 'react';
import PropTypes from 'prop-types';
import {Flex, Box, Text} from '@chakra-ui/react';
import styled from 'styled-components';

const StyledLabel = styled(Text)`
	color: black;
	font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
	padding-top: 5px;
	font-size: 14px;
	font-weight: bold;
`;

const StyledText = styled(Text)`
	color: black;
	font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
	padding-top: 5px;
	font-size: 14px;
`;

export const LabelValuePair = ({styles, label, value}) => (
	<Flex justify="flex-start" data-testid="label-value-pair">
		<Box data-testid="flexbox" sx={styles.flexBox}>
			<StyledLabel>{label}</StyledLabel>
		</Box>
		<Box justifyContent="flex-start" paddingLeft="20px">
			<StyledText>{value}</StyledText>
		</Box>
	</Flex>
);

LabelValuePair.propTypes = {
	styles: PropTypes.object,
	flexBox: PropTypes.object,
	label: PropTypes.string.isRequired,
	value: PropTypes.string.isRequired
};
