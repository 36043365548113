import React, {useEffect} from 'react';
import {useForm, useWatch, Controller} from 'react-hook-form';
import {Flex, Radio, RadioGroup, Stack, Text} from '@chakra-ui/react';
import {styles} from './select-data-default.style';
import {showErrors} from '../../common/util';
import {success} from '../ux-standard/toast/toast-service';
import {Button} from '../ux-standard/button/button';
import {
	getSelectDefaultsByMsId,
	saveSelectDefaults
} from '../../services/select-data-default-service';
import {DateField} from './date-field';
import {getFormattedDate} from '../../common/date-converter';

export const SelectDefaultData = () => {
	const {handleSubmit, control, setValue} = useForm({
		defaultValues: {
			searchType: 'pars',
			idType: 'claim',
			dateType: 'none',
			clientType: 'UHC',
			startDate: new Date(new Date().setFullYear(new Date().getFullYear() - 1)),
			endDate: new Date()
		}
	});

	const searchType = useWatch({control, name: 'searchType'});
	const idType = useWatch({control, name: 'idType'});
	const dateType = useWatch({control, name: 'dateType'});
	const startDate = useWatch({control, name: 'startDate'});
	const endDate = useWatch({control, name: 'endDate'});
	const clientType = useWatch({control, name: 'clientType'});

	const onSubmit = async formData => {
		try {
			formData.startDate = getFormattedDate(formData.startDate);
			formData.endDate = getFormattedDate(formData.endDate);
			await saveSelectDefaults(formData);
			success(['Settings Saved']);
		} catch (error) {
			showErrors(error);
		}
	};

	useEffect(() => {
		(async () => {
			try {
				const response = await getSelectDefaultsByMsId();
				if (response != null && response.length > 0) {
					setValue('searchType', response[0].searchType);
					setValue('idType', response[0].idType);
					setValue('dateType', response[0].dateType);
					setValue('startDate', new Date(response[0].startDate));
					setValue('endDate', new Date(response[0].endDate));
					setValue('clientType', response[0].clientType);
				}
			} catch (error) {
				showErrors(error);
			}
		})();
	}, []);

	return (
		<div style={{maxHeight: '1000px', overflowY: 'scroll'}}>
			<Text sx={styles.title}>{'Select Data Default'}</Text>
			<Flex sx={styles.outerContainer}>
				<form onSubmit={handleSubmit(onSubmit)} method="POST">
					<Flex sx={styles.container}>
						<Flex
							flex="1.5"
							borderRadius="4px"
							direction="column"
							maxWidth="550px"
							maxHeight="1000px"
							paddingBottom="30px"
						>
							<Text sx={styles.subtitle}>{'Search Type:'}</Text>
							<Controller
								name="searchType"
								control={control}
								defaultValue={searchType}
								rules={{required: true}}
								render={({field: {onChange, value}}) => (
									<RadioGroup
										colorScheme="orange"
										{...styles.radioGroup}
										onChange={onChange}
										value={value}
										name="searchType"
									>
										<Stack spacing={2} direction="column">
											<Radio value="pars">
												{
													'PARS (UHC ODAR and Commercial Claim Miner)'
												}
											</Radio>
											<Radio value="allsources">
												{'All Sources - United Only'}
											</Radio>
											<Radio value="facilitymv">
												{'MVs - Facility Only'}
											</Radio>
											<Radio value="tracking">
												{'Claim Research and Tracking'}
											</Radio>
										</Stack>
									</RadioGroup>
								)}
							/>
							<Text sx={styles.subtitle}>{'ID Type:'}</Text>
							<Controller
								name="idType"
								control={control}
								defaultValue={idType}
								render={({field: {onChange, value}}) => (
									<RadioGroup
										onChange={onChange}
										value={value}
										colorScheme="orange"
										{...styles.radioGroup}
										name="idType"
									>
										<Stack spacing={2} direction="column">
											<Radio value="claim">{'Claim'}</Radio>
											<Radio value="member">{'Member'}</Radio>
											{searchType === 'pars' ||
											searchType === 'tracking' ? (
												<Radio value="tin">{'TIN'}</Radio>
											) : null}
											{searchType === 'tracking' &&
											clientType === 'COM' ? (
												<Radio value="userstory">
													{'User Story ID'}
												</Radio>
											) : null}
											{searchType === 'pars' ||
											(searchType === 'tracking' &&
												clientType === 'UHC') ? (
												<Radio value="uid">
													{'UID (United Only)'}
												</Radio>
											) : null}
											{searchType === 'pars' ||
											(searchType === 'tracking' &&
												clientType === 'UHC') ? (
												<Radio value="mpin">
													{'MPIN (United Only)'}
												</Radio>
											) : null}
										</Stack>
									</RadioGroup>
								)}
							/>
							<Controller
								name="clientType"
								control={control}
								defaultValue={clientType}
								render={({field: {onChange, value}}) =>
									searchType === 'tracking' ? (
										<div>
											<Text sx={styles.subtitle}>
												{'Client Type:'}
											</Text>
											<RadioGroup
												onChange={onChange}
												value={value}
												colorScheme="orange"
												{...styles.radioGroup}
												name="clientType"
											>
												<Stack spacing={2} direction="column">
													<Radio value="COM">
														{'Commercial'}
													</Radio>
													<Radio value="UHC">{'United'}</Radio>
												</Stack>
											</RadioGroup>
										</div>
									) : null
								}
							/>
						</Flex>
						<Flex
							flex="1"
							borderRadius="4px"
							direction="column"
							width="230px"
							height="381px"
						>
							{searchType === 'allsources' && idType === 'member' && (
								<div>
									<Text sx={styles.subtitle}>{'Date Range:'}</Text>
									<Controller
										name="dateType"
										control={control}
										defaultValue={dateType}
										render={({field: {onChange, value}}) => (
											<RadioGroup
												onChange={onChange}
												value={value}
												colorScheme="orange"
												{...styles.radioGroup}
												name="dateType"
											>
												<Stack spacing={2} direction="column">
													<Radio value="none">{'None'}</Radio>
													<Radio value="paid">
														{'Paid Date'}
													</Radio>
													<Radio value="fdos">
														{'First Date of Service'}
													</Radio>
													<Radio value="ldos">
														{'Last Date of Service'}
													</Radio>
													<div style={{padding: '20px'}}></div>
													{searchType === 'allsources' &&
													idType === 'member' &&
													dateType !== 'none' ? (
														<Stack
															spacing={1}
															direction={'column'}
														>
															<DateField
																label="Start Date"
																name="startDate"
																control={control}
																defaultValue={startDate}
															/>
															<DateField
																label="End Date"
																name="endDate"
																control={control}
																defaultValue={endDate}
															/>
														</Stack>
													) : null}
												</Stack>
											</RadioGroup>
										)}
									/>
								</div>
							)}
						</Flex>
					</Flex>
					<Flex style={{padding: '10px'}} justifyContent="flex-end">
						<Button
							colorScheme="black"
							value="save"
							label="Save"
							size="medium"
							type="submit"
						/>
					</Flex>
				</form>
			</Flex>
		</div>
	);
};
