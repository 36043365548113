export const styleConfig = {
	baseStyle: {
		boxSizing: 'border-box',
		border: '1px solid',
		borderColor: 'var(--grey6)',
		borderRadius: '0',
		margin: '0',
		color: 'var(--grey1)',
		width: '100%',
		alignSelf: 'flex-start',
		padding: '0 calc(0.75 * var(--unit)) 0 calc(0.75 * var(--unit))',
		fontSize: '0.85rem',
		height: '2rem',
		mr: '0.5rem',
		_focus: {
			outline: '1px solid var(--teal3)',
			boxShadow: '0 0 5px var(--teal3Alpha50)',
			borderColor: 'var(--grey6)'
		},
		background: '#fff',
		_disabled: {
			background: 'inherit',
			cursor: 'not-allowed'
		}
	},
	variants: {
		green: {
			borderColor: 'green.500'
		},
		blue: {
			borderColor: 'blue.500'
		},
		normal: {}
	},
	defaultProps: {
		size: 'md',
		variant: 'normal'
	}
};
