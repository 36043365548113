const headerHeight = '114px';
const footerHeight = '114px';

export const styleConfig = {
	parts: ['container'],
	baseStyle: {
		container: {
			marginBottom: '50px',
			minHeight: 'calc(100vh - 220px)',
			position: 'relative'
		}
	},
	variants: {
		default: {},
		stickyHeader: {
			container: {pt: headerHeight}
		},
		stickyFooter: {
			container: {mb: footerHeight}
		},
		stickyHeaderAndFooter: {
			container: {pt: headerHeight, mb: footerHeight}
		}
	},
	defaultProps: {
		variant: 'default'
	}
};
