import {useEffect, useState} from 'react';
import {
	Flex,
	Button,
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalFooter,
	ModalBody,
	Divider,
	Box,
	Checkbox
} from '@chakra-ui/react';
import {FadeLoader} from 'react-spinners';
import {styles} from './saveFilterModal.style';
import {InputText} from '../ux-standard/input-text/input-text';
import {getSaverFilter} from '../../services/data-grid-service';
import {error} from '../ux-standard/toast/toast-service';
import PropTypes from 'prop-types';

export const SaveFilterModal = ({
	isOpen,
	onClose,
	filterName,
	filterNameValue,
	selectedFilterName
}) => {
	const [isChecked, setIsChecked] = useState(false);
	const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
		// This effect will run when the modal is about to close
		return () => {
			setIsLoading(false);
		};
	}, [isOpen]);

	const handleCheckboxChange = () => {
		setIsChecked(!isChecked);
	};

	const onSaveFlag = async () => {
		setIsLoading(true);
		try {
			const response = await getSaverFilter(filterNameValue);
			if (response.length === 0) {
				selectedFilterName(filterNameValue);
			} else {
				setIsLoading(false);
				error(['Filter name already exists']);
			}
		} catch (error) {}
	};

	return (
		<Modal isOpen={isOpen} onClose={onClose} size="2xl">
			<ModalOverlay />
			<ModalContent sx={{width: '450px'}}>
				<ModalHeader textAlign="center">
					<Flex sx={styles.header}>
						<h1>{'Save Filter'}</h1>
					</Flex>
				</ModalHeader>
				<Divider />
				<ModalBody id="modal-body">
					<Box sx={{paddingTop: '15px', paddingBottom: '15px'}}>
						<form>
							<InputText
								name="Filter Name"
								value={filterNameValue}
								type="text"
								placeholder="Enter a filter name"
								onChange={event => {
									filterName(event.target.value);
								}}
								width="260px"
								height="30px"
								borderRadius="3px"
								defaultValue=""
							/>
						</form>
						<Box sx={{marginTop: '10px'}}>
							<Checkbox
								isChecked={isChecked}
								onChange={handleCheckboxChange}
								colorScheme="teal" // Customize the color scheme
							>
								{'Set as Default'}
							</Checkbox>
						</Box>
					</Box>
				</ModalBody>
				<Divider />
				<ModalFooter>
					<Button
						disabled={!filterNameValue}
						sx={{marginRight: '10px'}}
						colorScheme="blue"
						onClick={onSaveFlag}
					>
						{'Save'}
					</Button>
					<Button colorScheme="blue" onClick={onClose}>
						{'Close'}
					</Button>
				</ModalFooter>
				{isLoading && (
					<div sx={styles.spinner}>
						<FadeLoader data-testid="spinner" loading={isLoading} />
					</div>
				)}
			</ModalContent>
		</Modal>
	);
};

SaveFilterModal.propTypes = {
	isOpen: PropTypes.bool.isRequired,
	onClose: PropTypes.func.isRequired,
	filterName: PropTypes.func.isRequired,
	filterNameValue: PropTypes.string.isRequired,
	selectedFilterName: PropTypes.func.isRequired
};
