export const MainContentModel = {
	imageData: [
		{
			image: '../../../select-data.png',
			title: 'Select Data',
			hoverText: 'Retrieves claim data from user defined list.',
			link: '/investigation/select-data'
		},
		{
			image: '../../../performance-dashboard.png',
			title: 'Performance Dashboard',
			hoverText:
				'Presents a high level-summary of recoveries for reporting anlytics trends and drilling down to large sets of data for analysis.',
			link: '/exploration/performance-dashboard'
		},
		{
			image: '../../../analysis-dashboard.png',
			title: 'Analysis Dashboard',
			hoverText: 'Presents large sets of claim data for analysis.',
			link: '/exploration/analysis-dashboard'
		},
		{
			image: '../../../data-grid.png',
			title: 'Data Grid',
			hoverText: 'Presents claim data in a spreadsheet-style layout.',
			link: '/investigation/data-grid'
		},
		{
			image: '../../../claim-research-and-tracking.png',
			title: 'Claim Research and Tracking',
			hoverText: 'A claim research and tracking tool used for new identifications.',
			link: '/investigation/claims-research-tracking-united'
		},
		{
			image: '../../../vendor-insourcing.png',
			title: 'Vendor Insourcing',
			hoverText: 'Presents Vendor Insourcing identification trends.',
			link: '/exploration/vendor-insourcing'
		},
		{
			image: '../../../user-reporting.png',
			title: 'User Reporting',
			hoverText: 'A self-service Tableau reporting tool.',
			link: '/tools/user-reporting'
		},
		{
			image: '../../../data-load-status.png',
			title: 'Data Load Status',
			hoverText:
				'Displays when data was last loaded for various clients and lines of business.',
			link: '/exploration/data-load-status'
		}
	],
	sections: [
		{
			title: 'Messages',
			text: '',
			link: 'https://hubconnect.uhg.com/groups/precision-analytics-research-system',
			button: 'Messages',
			icon: 'announcement.svg'
		},
		{
			title: 'Yammer',
			text: 'Yammer allows the PARS team to communicate with our user community in a common collaboration space through surveys, polls, discussions, supporting documentation, upcoming training sessions, release details, and other featured content.',
			link: 'https://web.yammer.com/main/groups/eyJfdHlwZSI6Ikdyb3VwIiwiaWQiOiI1NTQ4MDA1Nzg1NiJ9/all',
			button: 'Yammer',
			icon: 'Yammer.svg'
		},
		{
			title: 'Ideas',
			text: 'Have an idea on how to improve PARS?  Are there any features or visualizations you would like to see in future versions of PARS? Submit your idea and help drive the future direction of PARS!',
			link: '../#!/preferences/issue',
			button: 'Ideas',
			icon: 'idea.svg'
		}
	]
};
