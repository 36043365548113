export const styles = {
	parts: ['progressIcon'],
	baseStyle: {
		progressIcon: {
			pointerEvents: 'auto',
			position: 'absolute',
			left: 0,
			right: 0,
			top: 0,
			bottom: 0,
			opacity: 0.35,
			backgroundColor: 'black',
			justifyContent: 'center',
			alignItems: 'center',
			zIndex: 9999
		},
		spinner: {
			position: 'fixed',
			left: '50%',
			top: '50%'
		}
	}
};
