import React, {useState, useEffect} from 'react';
import {useForm} from 'react-hook-form';
import {
	Box,
	Drawer,
	DrawerOverlay,
	DrawerContent,
	DrawerCloseButton,
	Button,
	Tab,
	TabList,
	TabPanel,
	TabPanels,
	Tabs,
	Flex,
	Image,
	Text,
	Tooltip,
	Menu,
	MenuButton,
	MenuList,
	MenuItemOption,
	MenuOptionGroup
} from '@chakra-ui/react';
import arrow_right from '../../../../assets/images/arrow_right.svg';
import {styles} from './facility-filter.style';
import {Calendar} from '../../../../../src/components/ux-standard/calendar/calendar';
import add from '../../../../assets/images/addfilter.png';
import {InputText} from '../../../../../src/components/ux-standard/input-text/input-text';
import save from '../../../../assets/images/save.svg';
import {AiFillCaretDown} from 'react-icons/ai';
import styled from 'styled-components';
import {SelectMenu} from './select-menu';

import {Flag} from './flag';
import PropTypes from 'prop-types';
import {formatDate} from '../../../../globalFunctions';
const StyledIcon = styled(AiFillCaretDown)`
	margin-left: 2px;
	margin-top: 2px;
	width: 10px;
	height: 10px;
`;

export const FacilityFilter = ({
	onFilterSection,
	inputValue,
	flagsDataRes,
	onSaveFlag
}) => {
	const {register} = useForm();
	const [filterOptions, setFilterOptions] = useState({
		clientType: [
			{id: 1, label: 'Commercial', selected: false},
			{id: 2, label: 'United', selected: false}
		],
		clientName: [
			{id: 1, label: 'CnS', selected: false},
			{id: 2, label: 'Empire', selected: false},
			{id: 3, label: 'EnI Core', selected: false},
			{id: 4, label: 'EnI Private Exchange', selected: false},
			{id: 5, label: 'EnI Public Exchange', selected: false},
			{id: 6, label: 'Harvard Pilgrim', selected: false},
			{id: 7, label: 'Medica', selected: false},
			{id: 8, label: 'MnR', selected: false},
			{id: 9, label: 'OPTUM CPH', selected: false},
			{id: 10, label: 'OPTUM CPH', selected: false},
			{id: 11, label: 'OPTUM Phys', selected: false},

			{id: 12, label: 'UBH', selected: false},
			{id: 13, label: 'UHC ASO', selected: false}
		],
		platform: [
			{id: 1, label: 'AMISYS', selected: false},
			{id: 2, label: 'CAM', selected: false},
			{id: 3, label: 'CIRRUS', selected: false},
			{id: 4, label: 'COSMOS', selected: false},
			{id: 5, label: 'CSP', selected: false},
			{id: 6, label: 'FACETS', selected: false},
			{id: 7, label: 'GFLX', selected: false},
			{id: 8, label: 'NDMND', selected: false},
			{id: 9, label: 'NICE', selected: false},
			{id: 10, label: 'OXHP', selected: false},
			{id: 11, label: 'QICLINK', selected: false},
			{id: 12, label: 'UNET', selected: false},
			{id: 13, label: 'UNISON', selected: false}
		],
		vendorType: [
			{id: 1, label: 'Collections Vendor', selected: false},
			{id: 2, label: 'Credit Balance Vendor', selected: false},
			{id: 3, label: 'Data Mining Other', selected: false},
			{id: 4, label: 'Data Mining Vendor', selected: false},
			{id: 5, label: 'Fraud and Abuse Bypass Compliance', selected: false},
			{
				id: 6,
				label: 'Fraud and Abuse External Vendors Bypass Compliance',
				selected: false
			},
			{
				id: 7,
				label: 'Fraud and Abuse External Vendors Follow Compliance',
				selected: false
			},
			{id: 8, label: 'Fraud and Abuse Follow Compliance', selected: false},
			{id: 9, label: 'Health Group Non-ARO Activity', selected: false},
			{id: 10, label: 'Health and Human Services', selected: false},
			{id: 11, label: 'Optum Behavioral Health Solutions', selected: false},
			{id: 12, label: 'Premium Audit Internal', selected: false},
			{id: 13, label: 'Premium Audit Services', selected: false},
			{id: 14, label: 'Reclamation', selected: false},
			{id: 15, label: 'Subrogation Vendor', selected: false},
			{
				id: 16,
				label: 'United Benefit Operations recoveries – Out of compliance',
				selected: false
			},
			{id: 17, label: 'UnitedHealth Group Business Unit', selected: false},

			{id: 18, label: 'UnitedHealth Group Other Compliancet', selected: false}
		],
		vendorName: [
			{id: 1, label: '4600 GROUP', selected: false},
			{id: 2, label: 'ACCENT', selected: false},
			{id: 3, label: 'ACCENTURE', selected: false},
			{
				id: 4,
				label: 'ACQUISITION STATE REGULATION COMPLIANCE EXCEPTIONS',
				selected: false
			},
			{id: 5, label: 'AMERICAN CHIROPRATIC NETWORKS', selected: false},
			{id: 6, label: 'AUDIT n RECOVERY OPERATIONS', selected: false},
			{id: 7, label: 'Advize Health', selected: false},
			{id: 8, label: 'CDR ASSOCIATES, LLC', selected: false},
			{id: 9, label: 'CGI', selected: false},
			{id: 10, label: 'CHART-TECH, INC', selected: false},
			{id: 11, label: 'COMPUTER SCIENCES CORPORATION', selected: false},
			{id: 12, label: 'CONCENTRA PREFERRED SYSTEMS', selected: false},
			{id: 13, label: 'CORVEL', selected: false},
			{id: 15, label: 'COTIVITI', selected: false},
			{id: 16, label: 'Change Healthcare', selected: false},
			{id: 17, label: 'END GAME STRATEGY', selected: false},
			{id: 18, label: 'EQUIAN DATAMINING', selected: false},
			{id: 19, label: 'EQUICLAIM', selected: false},
			{id: 20, label: 'FIRST RECOVERY GROUP', selected: false},
			{id: 21, label: 'HEALTH DATA SOLUTIONS', selected: false},
			{id: 22, label: 'HEALTH MANAGEMENT SYSTEMS, INC.', selected: false},
			{id: 23, label: 'HEALTHDATAINSIGHTS, INC.', selected: false},
			{id: 24, label: 'Health and Human Services - Ben Ops', selected: false},
			{id: 25, label: 'Health and Human Services - Data Mining', selected: false},
			{id: 26, label: 'INTERNAL RECOVERY', selected: false},
			{id: 27, label: 'INTERNAL-CCM', selected: false},
			{id: 28, label: 'JOHNSON n ROUNTREE', selected: false},
			{id: 29, label: 'MAMSI COB PROJECT', selected: false},
			{id: 30, label: 'MEDASSURANT', selected: false},

			{id: 31, label: 'MEDICA', selected: false},
			{id: 32, label: 'MEDRECOVERY MANAGEMENT LLC', selected: false},
			{id: 33, label: 'MEDREVIEW', selected: false},
			{id: 34, label: 'MICROVU', selected: false},
			{id: 35, label: 'Multiplan', selected: false},
			{id: 36, label: 'NATIONAL AUDIT SERVICES', selected: false},
			{id: 37, label: 'OMNICLAIM, INC.', selected: false},
			{id: 38, label: 'OPTUM FRAUD AND ABUSE', selected: false},
			{id: 39, label: 'OPTUM PAYMENT INTEGRITY', selected: false},
			{id: 40, label: 'OPTUM PREMIUM AUDIT SERVICES', selected: false},
			{id: 41, label: 'OPTUM SUBRO OVERPAYMENTS', selected: false},
			{id: 42, label: 'OPTUM SUBROGATION', selected: false},
			{id: 43, label: 'Optum Behavioral Health', selected: false},
			{id: 44, label: 'OrthoNet', selected: false},
			{id: 45, label: 'PERFORMANT', selected: false},
			{id: 46, label: 'ROI HEALTHDATA SOLUTIONS', selected: false},
			{id: 47, label: 'SANTE ANALYTIC', selected: false},

			{id: 48, label: 'SCIO HEALTH ANALYTICS', selected: false},
			{id: 49, label: 'THE RAWLINGS COMPANY', selected: false},
			{id: 50, label: 'UHC BENEFIT OPERATIONS', selected: false},
			{id: 51, label: 'Wipro LLP', selected: false}
		],
		lineOfBusiness: [
			{id: 1, label: 'ASO', selected: false},
			{id: 2, label: 'Commercial', selected: false},
			{id: 3, label: 'Medicaid', selected: false},
			{id: 4, label: 'Medicare', selected: false},
			{id: 5, label: 'Unknown', selected: false}
		],
		auditCategory: [
			{id: 1, label: 'CLAIM PROCESSOR ERROR', selected: false},
			{id: 2, label: 'COB', selected: false},
			{id: 3, label: 'CONTRACT ERROR', selected: false},
			{id: 4, label: 'DUPLICATE PAYMENT', selected: false},
			{id: 5, label: 'FINANCIAL', selected: false},
			{id: 6, label: 'HOSPITAL BILL AUDIT', selected: false},
			{id: 7, label: 'HOSPITAL BILL AUDIT PAF', selected: false},
			{id: 8, label: 'AMEDICAL RETRO-TERMSSO', selected: false},
			{id: 9, label: 'OTHER', selected: false},
			{id: 10, label: 'PAR PAID AS NON-PAR/WRONG PROVIDER', selected: false},
			{id: 11, label: 'PROVIDER BILLING ERROR', selected: false},
			{id: 12, label: 'SUBROGATION', selected: false},
			{id: 13, label: 'SYSTEM LIMITATION', selected: false},
			{id: 14, label: 'WORKER`S COMPENSATION', selected: false},
			{id: 15, label: 'WRONG MEMBER', selected: false}
		],
		subCategory: [
			{id: 1, label: 'Analytics - Research Dept: COB', selected: false},
			{id: 2, label: 'Analytics - Research Dept: Data Mining', selected: false},
			{id: 3, label: 'Analytics - Research Dept: FWAE', selected: false},
			{id: 4, label: 'Exclusion - Internal ID', selected: false},
			{
				id: 5,
				label: 'Exclusion - ODAR deactivated/rejected/pending',
				selected: false
			},
			{
				id: 6,
				label: 'Exclusion - Write-off - Closed amt equal to ID amt',
				selected: false
			},
			{id: 7, label: 'Operations - Research Dept: COB', selected: false},
			{id: 8, label: 'Operations - Research Dept: Data Mining', selected: false},

			{id: 9, label: 'Operations - Research Dept: FWAE', selected: false}
		]
	});
	const [isOpen, setIsOpen] = useState(false);

	const [claimNote, setClaimNote] = useState('');

	const handleOpen = () => setIsOpen(true);
	const handleClose = () => setIsOpen(false);

	const [flags, setFlags] = useState([]);
	const [, setFlagsOptions] = useState([]);
	const [startDate, setStartDate] = useState('');
	const [endDate, setEndDate] = useState('');
	const [selectedFlagData, setSelectedFlagData] = useState([]);

	const [selectedOptions, setSelectedOptions] = useState({});

	useEffect(() => {
		onGetFlagData();
	}, []);

	useEffect(() => {
		onFilterSection(
			filterOptions,
			claimNote,
			startDate,
			endDate,
			selectedOptions.SelectDate,
			selectedFlagData
		);
	}, [filterOptions, startDate, endDate, selectedOptions, selectedFlagData]);

	const onGetFlagData = () => {
		setFlagsOptions(flagsDataRes);
	};

	const addNewFlag = () => {
		const flagName = `Flag ${flags.length}`;
		const newFlag = {
			id: new Date().getTime(),
			name: flagName
		};

		setFlags(prevFlags => [...prevFlags, newFlag]);
	};

	const handleDelete = id => {
		setFlags(flags.filter(flag => flag.id !== id));
		setSelectedFlagData(selectedFlagData.filter(flag => flag.flagId !== id));
	};

	const handleSave = () => {
		setIsOpen(false);
		onSaveFlag(true);
	};

	const onClickAll = dropDownType => {
		let filteredArray = filterOptions;
		let dropdownKey = dropDownType;

		filteredArray = filteredArray[dropdownKey].map(item => {
			return {...item, selected: true};
		});

		setFilterOptions(prevData => ({
			...prevData,
			[dropdownKey]: filteredArray
		}));
	};

	const onSelectItem = (val, dropDownType) => {
		let filteredArray = filterOptions;
		let dropdownKey = dropDownType;

		filteredArray = filteredArray[dropdownKey].map(item => {
			if (item.id === val.id) {
				return {...item, selected: true};
			}
			return item;
		});

		setFilterOptions(prevData => ({
			...prevData,
			[dropdownKey]: filteredArray
		}));
	};

	const removeSelectedItem = (val, dropDownType) => {
		let filteredArray = filterOptions;
		let dropdownKey = dropDownType;

		filteredArray = filteredArray[dropdownKey].map(item => {
			if (item.id === val.id) {
				return {...item, selected: false};
			}
			return item;
		});

		setFilterOptions(prevData => ({
			...prevData,
			[dropdownKey]: filteredArray
		}));
	};

	const onClickNone = dropDownType => {
		let filteredArray = filterOptions;
		let dropdownKey = dropDownType;

		filteredArray = filteredArray[dropdownKey].map(item => {
			return {...item, selected: false};
		});

		setFilterOptions(prevData => ({
			...prevData,
			[dropdownKey]: filteredArray
		}));
	};

	const onHandleSelectedFlag = event => {
		const defaultFlagObj = {
			databaseColumn: 'IS_DM_OPS_TEAM_CCM',
			name: 'DM Ops Team CCM - UHC',
			value: true
		};

		const incomingObject = Object.keys(event).length === 0 ? defaultFlagObj : event;

		const matchIndex = flags.findIndex(
			obj =>
				obj.id === incomingObject.flagId ||
				obj.databaseColumn === incomingObject.databaseColumn
		);

		if (matchIndex !== -1) {
			const updatedArray2 = [...selectedFlagData];
			updatedArray2[matchIndex] = {
				...updatedArray2[matchIndex],
				...incomingObject
			};
			setSelectedFlagData(updatedArray2);
		} else {
			setSelectedFlagData([...selectedFlagData, incomingObject]);
		}
	};

	return (
		<div className="sidebar">
			<Image left="0px" top="0px" src={arrow_right} onClick={handleOpen} />
			<Box>
				<Drawer isOpen={isOpen} placement="left" onClose={handleClose}>
					<DrawerOverlay>
						<DrawerContent>
							<DrawerCloseButton />
							<div style={{padding: '10px'}}></div>
							<Tabs variant={'unstyled'} size="sm">
								<TabList spacing={8}>
									<Tab {...styles.tabs}>{'Filters'}</Tab>
									<Tab {...styles.tabs}>{'Dates'}</Tab>
									<Tab {...styles.tabs}>{'Flags'}</Tab>
									<div style={{paddingLeft: '10px'}}></div>
									<Tooltip
										hasArrow
										label="Save Filter"
										placement="bottom"
									>
										<Image
											src={save}
											style={{cursor: 'pointer'}}
											onClick={handleSave}
										/>
									</Tooltip>
								</TabList>
								<TabPanels>
									<TabPanel>
										<SelectMenu
											title="Client Type"
											options={filterOptions.clientType}
											dropdownType={'clientType'}
											onClickNone={val => onClickNone(val)}
											onClickAll={val => onClickAll(val)}
											unSelect={(unSelectedItem, dropdownType) =>
												removeSelectedItem(
													unSelectedItem,
													dropdownType
												)
											}
											handleSelectedOptions={setSelectedOptions}
											selectOptions={(item, dropdownType) =>
												onSelectItem(item, dropdownType)
											}
										/>
										<SelectMenu
											title="Client Name"
											options={filterOptions.clientName}
											dropdownType={'clientName'}
											onClickNone={val => onClickNone(val)}
											onClickAll={val => onClickAll(val)}
											unSelect={(unSelectedItem, dropdownType) =>
												removeSelectedItem(
													unSelectedItem,
													dropdownType
												)
											}
											handleSelectedOptions={setSelectedOptions}
											selectOptions={(item, dropdownType) =>
												onSelectItem(item, dropdownType)
											}
										/>
										<SelectMenu
											title="Platform"
											options={filterOptions.platform}
											dropdownType={'platform'}
											onClickNone={val => onClickNone(val)}
											onClickAll={val => onClickAll(val)}
											unSelect={(unSelectedItem, dropdownType) =>
												removeSelectedItem(
													unSelectedItem,
													dropdownType
												)
											}
											handleSelectedOptions={setSelectedOptions}
											selectOptions={(item, dropdownType) =>
												onSelectItem(item, dropdownType)
											}
										/>
										<SelectMenu
											title="Vendor Type"
											options={filterOptions.vendorType}
											dropdownType={'vendorType'}
											onClickNone={val => onClickNone(val)}
											onClickAll={val => onClickAll(val)}
											unSelect={(unSelectedItem, dropdownType) =>
												removeSelectedItem(
													unSelectedItem,
													dropdownType
												)
											}
											handleSelectedOptions={setSelectedOptions}
											selectOptions={(item, dropdownType) =>
												onSelectItem(item, dropdownType)
											}
										/>
										<SelectMenu
											title="Vendor Name"
											options={filterOptions.vendorName}
											dropdownType={'vendorName'}
											onClickNone={val => onClickNone(val)}
											onClickAll={val => onClickAll(val)}
											unSelect={(unSelectedItem, dropdownType) =>
												removeSelectedItem(
													unSelectedItem,
													dropdownType
												)
											}
											handleSelectedOptions={setSelectedOptions}
											selectOptions={(item, dropdownType) =>
												onSelectItem(item, dropdownType)
											}
										/>
										<SelectMenu
											title="Line of Business"
											options={filterOptions.lineOfBusiness}
											dropdownType={'lineOfBusiness'}
											onClickNone={val => onClickNone(val)}
											onClickAll={val => onClickAll(val)}
											unSelect={(unSelectedItem, dropdownType) =>
												removeSelectedItem(
													unSelectedItem,
													dropdownType
												)
											}
											handleSelectedOptions={setSelectedOptions}
											selectOptions={(item, dropdownType) =>
												onSelectItem(item, dropdownType)
											}
										/>
										<SelectMenu
											title="Audit Category"
											options={filterOptions.auditCategory}
											dropdownType={'auditCategory'}
											onClickNone={val => onClickNone(val)}
											onClickAll={val => onClickAll(val)}
											unSelect={(unSelectedItem, dropdownType) =>
												removeSelectedItem(
													unSelectedItem,
													dropdownType
												)
											}
											handleSelectedOptions={setSelectedOptions}
											selectOptions={(item, dropdownType) =>
												onSelectItem(item, dropdownType)
											}
										/>
										<SelectMenu
											title="Sub Category"
											options={filterOptions.subCategory}
											dropdownType={'subCategory'}
											onClickNone={val => onClickNone(val)}
											onClickAll={val => onClickAll(val)}
											unSelect={(unSelectedItem, dropdownType) =>
												removeSelectedItem(
													unSelectedItem,
													dropdownType
												)
											}
											handleSelectedOptions={setSelectedOptions}
											selectOptions={(item, dropdownType) =>
												onSelectItem(item, dropdownType)
											}
										/>
										<Text sx={styles.subtitle}>
											{'Claim Note Search'}
										</Text>
										<form>
											<InputText
												name="claimNoteSearch"
												{...register('claimNoteSearch')}
												type="text"
												placeholder=""
												onChange={event => {
													setClaimNote(event.target.value);
													inputValue(
														filterOptions,
														event.target.value
													);
												}}
												width="260px"
												height="30px"
												borderRadius="3px"
												defaultValue=""
											/>
										</form>
									</TabPanel>
									<TabPanel>
										<Flex
											flex="1"
											borderRadius="4px"
											direction="column"
											width="230px"
											height="400px"
											paddingBottom="5px"
										>
											<Text sx={styles.subtitle}>
												{'Select Date'}
											</Text>
											<Menu closeOnSelect={false}>
												<MenuButton
													as={Button}
													colorScheme="white"
													color="grey"
													border="1px solid gray"
													minWidth="200px"
													height="33px"
												>
													<Box
														as="span"
														display="flex"
														justifyContent="center"
														alignItems="center"
													>
														<Text sx={styles.menuButtonText}>
															{'Select'}
														</Text>
														<StyledIcon />
													</Box>
												</MenuButton>
												<MenuList minWidth="260px" height="120px">
													<MenuOptionGroup
														defaultValue=""
														type="radio"
														onChange={value =>
															setSelectedOptions(
																prevState => ({
																	...prevState,
																	SelectDate: value
																})
															)
														}
													>
														<MenuItemOption value="ID Date">
															<Text
																sx={styles.menuButtonText}
															>
																{'ID Date'}
															</Text>
														</MenuItemOption>
														<MenuItemOption value="Hist ID Date - United Only">
															<Text
																sx={styles.menuButtonText}
															>
																{
																	'Hist ID Date - United Only'
																}
															</Text>
														</MenuItemOption>
														<MenuItemOption value="Loaded Radar Date">
															<Text
																sx={styles.menuButtonText}
															>
																{'Loaded Radar Date'}
															</Text>
														</MenuItemOption>
													</MenuOptionGroup>
												</MenuList>
											</Menu>
											<div style={{padding: '5px'}}></div>
											<Text sx={styles.subtitle}>
												{'Start Date'}
											</Text>
											<Calendar
												size="sm"
												onChange={date =>
													setStartDate(formatDate(date.value))
												}
												showIcon
												showButtonBar
											/>
											<div style={{padding: '5px'}}></div>
											<Text sx={styles.subtitle}>{'End Date'}</Text>
											<Calendar
												size="small"
												onChange={date =>
													setEndDate(formatDate(date.value))
												}
												showIcon
												showButtonBar
											/>
										</Flex>
									</TabPanel>
									<TabPanel>
										{flags.map((flag, index) => (
											<Flag
												key={flag.id}
												flag={flag}
												options={flagsDataRes}
												addNewFlag={addNewFlag}
												flagCount={index + 1}
												setSelectedOptions={event =>
													onHandleSelectedFlag(event)
												}
												handleDelete={() => handleDelete(flag.id)}
											/>
										))}
										<Flex
											onClick={addNewFlag}
											sx={{corsor: 'pointer', marginTop: '15px'}}
											direction="row"
										>
											<Image
												src={add}
												style={{cursor: 'pointer'}}
												// onClick={addNewFlag}
											/>
											<Text padding="5px" sx={styles.subtitle}>
												{'Add New'}
											</Text>
										</Flex>
									</TabPanel>
								</TabPanels>
							</Tabs>
						</DrawerContent>
					</DrawerOverlay>
				</Drawer>
			</Box>
		</div>
	);
};

FacilityFilter.propTypes = {
	flagsDataRes: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.number.isRequired,
			label: PropTypes.string.isRequired,
			selected: PropTypes.bool.isRequired
		})
	).isRequired,
	onSaveFlag: PropTypes.func.isRequired,
	inputValue: PropTypes.func.isRequired,
	onFilterSection: PropTypes.func.isRequired
};
