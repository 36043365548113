export const styles = {
	btnConatiner: {
		marginTop: '10px'
	},
	exportBtn: {
		fontWeight: 'normal',
		backgroundColor: '#434448'
	},
	column: {whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'},
	arrowImage: {
		height: '20px',
		width: '20px',
		marginLeft: '5px'
	}
};
