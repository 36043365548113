import React, {useEffect} from 'react';
import {onSignOut} from './auth-utils';

export const Logout = () => {
	useEffect(() => {
		onSignOut();
	}, []);

	return <div></div>;
};
