import React from 'react';
import PropTypes from 'prop-types';
import {Heading} from '@chakra-ui/react';
import {DataBoundary} from './data-boundary';
import {ErrorDisplay} from './error-boundaries/error-display';
import {LoaderDisplay} from './loader-display';

const ErrorMessage = () => {
	return (
		<ErrorDisplay
			extraErrorContent={
				<>
					<Heading as="h5" size="md">
						{"This page wasn't able to find the required data to function."}
					</Heading>
					<br></br>
				</>
			}
			errorMessage={
				'You may try to refresh the page and see if the problem resolves itself. If the problem continues please contact your system administrator.'
			}
		/>
	);
};

export const PageDataBoundary = props => {
	return (
		<DataBoundary
			{...props}
			errorDisplay={<ErrorMessage></ErrorMessage>}
			loaderDisplay={<LoaderDisplay></LoaderDisplay>}
		></DataBoundary>
	);
};

PageDataBoundary.propTypes = {
	itemsToCheck: PropTypes.arrayOf(PropTypes.any),
	isLoading: PropTypes.bool
};

PageDataBoundary.defaultProps = {
	itemsToCheck: [],
	isLoading: false
};
