import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {
	Box,
	Button,
	Flex,
	Image,
	Text,
	Menu,
	MenuButton,
	MenuList,
	MenuItemOption,
	MenuOptionGroup
} from '@chakra-ui/react';

import {styles} from './facility-filter.style';
import close from '../../../../assets/images/close.png';
import {AiFillCaretDown} from 'react-icons/ai';
import styled from 'styled-components';

const StyledIcon = styled(AiFillCaretDown)`
	margin-left: 2px;
	margin-top: 2px;
	width: 10px;
	height: 10px;
`;

export const Flag = ({setSelectedOptions, handleDelete, options, flag, flagCount}) => {
	const [toggle, setToggle] = useState(true);
	const [selectedValue, setSelectedValue] = useState('DM Ops Team CCM - UHC');
	const [selectedFlagValue, setSelectedFlagValue] = useState({});

	const deleteFlag = id => {
		handleDelete(id);
	};

	useEffect(() => {
		onHandleDropdownSelection();
	}, [selectedFlagValue, toggle]);

	const handleToggle = val => {
		setToggle(!toggle);
		const updatedObject = {...selectedFlagValue, value: val};
		setSelectedFlagValue(updatedObject);
	};

	const onChangeOption = async event => {
		setSelectedValue(event.name);
		let newFlagObj = {
			label: event.label,
			databaseColumn: event.databaseColumn,
			value: toggle,
			flagId: flag.id
		};

		setSelectedFlagValue(newFlagObj);
	};

	const onHandleDropdownSelection = () => {
		setSelectedOptions(selectedFlagValue);
	};

	return (
		<div style={{paddingTop: '5px'}}>
			<Flex sx={styles.flagContainer}>
				<Flex sx={styles.display}>
					<Image src={close} style={{cursor: 'pointer'}} onClick={deleteFlag} />
					<Text textAlign="center" sx={styles.subtitle}>
						{`Flag ${flagCount}`}
					</Text>
					<Button onClick={() => handleToggle(toggle ? false : true)}>
						{toggle ? 'Yes' : 'No'}
					</Button>
				</Flex>
				<Box width="260px" height="50px" alignItems="center" paddingLeft="20px">
					<Menu closeOnSelect={false}>
						<MenuButton
							as={Button}
							colorScheme="white"
							color="grey"
							border="1px solid gray"
							minWidth="230px"
							height="33px"
						>
							<Box
								as="span"
								display="flex"
								justifyContent="center"
								alignItems="center"
							>
								<Text sx={styles.menuButtonText}>
									{selectedValue ? selectedValue : 'Select'}
								</Text>
								<StyledIcon />
							</Box>
						</MenuButton>
						<MenuList minWidth="100px" height="200px" overflowY="auto">
							<MenuOptionGroup
								type="radio"
								defaultValue={'defaultValue'}
								value={selectedValue}
								onChange={onChangeOption}
							>
								{options.map(item => (
									<MenuItemOption key={item.id} value={item}>
										<Text sx={styles.menuButtonText}>
											{item.label}
										</Text>
									</MenuItemOption>
								))}
							</MenuOptionGroup>
						</MenuList>
					</Menu>
				</Box>
			</Flex>
		</div>
	);
};

Flag.propTypes = {
	setSelectedOptions: PropTypes.func.isRequired,
	handleDelete: PropTypes.func.isRequired,
	options: PropTypes.array.isRequired,
	flag: PropTypes.object,
	flagCount: PropTypes.number
};
