import React from 'react';
import PropTypes from 'prop-types';
import {
	Box,
	Button,
	Text,
	Menu,
	MenuButton,
	MenuList,
	MenuItemOption,
	MenuOptionGroup,
	MenuDivider
} from '@chakra-ui/react';
import {styles} from './facility-filter.style';
import {AiFillCaretDown, AiOutlineCheck, AiOutlineClose} from 'react-icons/ai';
import styled from 'styled-components';

const StyledIcon = styled(AiFillCaretDown)`
	margin-left: 2px;
	margin-top: 2px;
	width: 10px;
	height: 10px;
`;

const SelectAllIcon = styled(AiOutlineCheck)`
	margin-right: 2px;
	margin-top: 2px;
	width: 15px;
	height: 15px;
	color: orange;
`;

const UnselectAllIcon = styled(AiOutlineClose)`
	margin-right: 2px;
	margin-top: 2px;
	width: 15px;
	height: 15px;
	color: coral;
`;

export const SelectMenu = ({
	title,
	options,
	handleSelectedOptions,
	onClickNone,
	onClickAll,
	dropdownType,
	unSelect,
	selectOptions
}) => {
	let modifiedArray = options.filter(item => {
		if (item.selected) {
			return item;
		}
		return null;
	});

	let unSelectedArray = options.filter(item => {
		if (!item.selected) {
			return item;
		}
		return null;
	});

	const showDropDownLabel = () => {
		if (!modifiedArray || modifiedArray.length === 0) {
			return 'Select';
		} else if (modifiedArray.length === 1) {
			const selectedlabel = modifiedArray.map(item => {
				if (item.selected) {
					return item.label;
				} else {
					return null;
				}
			});

			return selectedlabel;
		} else if (modifiedArray.length > 1) {
			return `${modifiedArray.length.toString()} Select`;
		} else {
			return null;
		}
	};

	return (
		<>
			<Text sx={styles.subtitle}>{title}</Text>
			<Menu closeOnSelect={false}>
				<MenuButton
					as={Button}
					colorScheme="white"
					color="grey"
					border="1px solid gray"
					minWidth="260px"
					height="33px"
				>
					<Box
						as="span"
						display="flex"
						justifyContent="center"
						alignItems="center"
					>
						<Text sx={styles.menuButtonText}>{showDropDownLabel()}</Text>
						<StyledIcon />
					</Box>
				</MenuButton>
				<MenuList minWidth="260px" height={'150px'} overflowY="auto">
					<MenuOptionGroup defaultValue="" type="radio">
						<MenuItemOption
							onClick={() => onClickAll(dropdownType)}
							value="all"
						>
							<Box
								as="span"
								display="flex"
								justifyContent="flex-start"
								alignItems="flex-start"
								ta-load
							>
								<SelectAllIcon />
								<Text sx={styles.menuButtonText}>{'Select All'}</Text>
							</Box>
						</MenuItemOption>
						<MenuItemOption
							onClick={() => onClickNone(dropdownType)}
							value="none"
						>
							<Box
								as="span"
								display="flex"
								justifyContent="flex-start"
								alignItems="flex-start"
							>
								<UnselectAllIcon />
								<Text sx={styles.menuButtonText}>{'UnSelect All'}</Text>
							</Box>
						</MenuItemOption>
					</MenuOptionGroup>
					<MenuDivider />
					{modifiedArray || modifiedArray.length !== 0 ? (
						<div>
							<MenuOptionGroup
								defaultValue={[]}
								type="checkbox"
								onChange={values =>
									handleSelectedOptions(prevState => ({
										...prevState,
										[title]: values
									}))
								}
							>
								{modifiedArray.map(item => (
									<MenuItemOption
										onClick={() => unSelect(item, dropdownType)}
										sx={styles.selectedMenuOptions}
										key={item?.id}
										value={item}
									>
										<Box sx={{display: 'flex', flexDirection: 'row'}}>
											<UnselectAllIcon />
											<Text sx={styles.menuButtonText}>
												{item?.label}
											</Text>
										</Box>
									</MenuItemOption>
								))}
							</MenuOptionGroup>
						</div>
					) : null}
					<MenuDivider />
					{!unSelectedArray || unSelectedArray.length !== 0 ? (
						<MenuOptionGroup
							defaultValue={[]}
							type="checkbox"
							onChange={values =>
								handleSelectedOptions(prevState => ({
									...prevState,
									[title]: values
								}))
							}
						>
							{unSelectedArray.map(item => (
								<MenuItemOption
									onClick={() => selectOptions(item, dropdownType)}
									key={item?.id}
									value={item}
								>
									<Box sx={{display: 'flex', flexDirection: 'row'}}>
										<SelectAllIcon />
										<Text sx={styles.menuButtonText}>
											{item?.label}
										</Text>
									</Box>
								</MenuItemOption>
							))}
						</MenuOptionGroup>
					) : null}
				</MenuList>
			</Menu>
			<div style={{padding: '5px'}}></div>
		</>
	);
};

SelectMenu.propTypes = {
	title: PropTypes.string.isRequired,
	options: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.number.isRequired,
			label: PropTypes.string.isRequired,
			selected: PropTypes.bool.isRequired
		})
	).isRequired,
	handleSelectedOptions: PropTypes.func.isRequired,
	onClickNone: PropTypes.func.isRequired,
	onClickAll: PropTypes.func.isRequired,
	dropdownType: PropTypes.string.isRequired,
	unSelect: PropTypes.func.isRequired,
	selectOptions: PropTypes.func.isRequired
};
