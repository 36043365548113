export const styleConfig = {
	parts: ['main', 'messageContainer', 'message', 'button'],
	baseStyle: {
		main: {
			color: 'var(--grey1)',
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'space-between',
			height: '100%',
			width: '100%'
		},
		messageContainer: {
			width: '100%',
			display: 'flex'
		},
		message: {
			p: '0 calc(6 * var(--unit)) 0 calc(3 * var(--unit))'
		},
		button: {
			background: 'transparent',
			border: 'none',
			padding: 0,
			mt: 'calc(.25 * var(--unit))',
			_focus: {
				border: 'none',
				outline: 'none',
				boxShadow: 'none'
			},
			_hover: {
				'&:enabled': {
					backgroundColor: 'transparent',
					outline: 'none',
					border: 'none'
				}
			},
			_active: {
				'&:enabled': {
					backgroundColor: 'transparent',
					boxShadow: 'none',
					outline: 'none',
					outlineOffset: '-2px'
				}
			}
		},
		success: {}
	},
	sizes: {},
	variants: {
		success: {
			main: {
				background: 'var(--green7)'
			}
		},
		error: {
			main: {
				background: 'var(--red7)'
			}
		},
		info: {
			main: {
				background: 'var(--teal7)'
			}
		},
		warn: {
			main: {
				background: 'var(--yellow7)'
			}
		}
	},
	defaultProps: {variant: 'info'}
};
