import {theme as defaultTheme} from '@chakra-ui/react';

export const colors = {
	...defaultTheme.colors,
	transparent: 'transparent',
	current: 'currentColor',
	black: '#050505',
	white: '#FFFFFF',
	whiteAlpha: {
		50: 'rgba(255, 255, 255, 0.04)',
		100: 'rgba(255, 255, 255, 0.06)',
		200: 'rgba(255, 255, 255, 0.08)',
		300: 'rgba(255, 255, 255, 0.16)',
		400: 'rgba(255, 255, 255, 0.24)',
		500: 'rgba(255, 255, 255, 0.36)',
		600: 'rgba(255, 255, 255, 0.48)',
		700: 'rgba(255, 255, 255, 0.64)',
		800: 'rgba(255, 255, 255, 0.80)',
		900: 'rgba(255, 255, 255, 0.92)'
	},
	blackAlpha: {
		50: 'rgba(0, 0, 0, 0.04)',
		100: 'rgba(0, 0, 0, 0.06)',
		200: 'rgba(0, 0, 0, 0.08)',
		300: 'rgba(0, 0, 0, 0.16)',
		400: 'rgba(0, 0, 0, 0.24)',
		500: 'rgba(0, 0, 0, 0.36)',
		600: 'rgba(0, 0, 0, 0.48)',
		700: 'rgba(0, 0, 0, 0.64)',
		800: 'rgba(0, 0, 0, 0.80)',
		900: 'rgba(0, 0, 0, 0.92)'
	},
	gray: {
		25: '#F7F7F7',
		50: '#D7D8D9',
		100: '#C4C4C6',
		200: '#B0B1B3',
		300: '#9D9EA0',
		400: '#898A8D',
		500: '#76777A',
		600: '#626467',
		700: '#4F5054',
		800: '#3B3D41',
		900: '#282A2E'
	},
	red: {
		50: '#fff5f5',
		100: '#fed7d7',
		200: '#feb2b2',
		300: '#fc8181',
		400: '#f56565',
		500: '#e53e3e',
		600: '#c53030',
		700: '#9b2c2c',
		800: '#822727',
		900: '#63171b'
	},
	orange: {
		50: '#DDA278',
		100: '#D89361',
		200: '#CD7434',
		300: '#C7651E',
		400: '#C25608',
		500: '#C05621',
		600: '#B14F08',
		700: '#9F4707',
		800: '#8E3F06',
		900: '#7C3706'
	},
	yellow: {
		50: '#fffff0',
		100: '#fefcbf',
		200: '#faf089',
		300: '#f6e05e',
		400: '#ecc94b',
		500: '#d69e2e',
		600: '#b7791f',
		700: '#975a16',
		800: '#744210',
		900: '#5F370E'
	},
	green: {
		50: '#f0fff4',
		100: '#c6f6d5',
		200: '#9ae6b4',
		300: '#68d391',
		400: '#48bb78',
		500: '#38a169',
		600: '#2f855a',
		700: '#276749',
		800: '#22543d',
		900: '#1C4532'
	},
	teal: {
		50: '#E6FFFA',
		100: '#B2F5EA',
		200: '#81E6D9',
		300: '#4FD1C5',
		400: '#38B2AC',
		500: '#319795',
		600: '#2C7A7B',
		700: '#285E61',
		800: '#234E52',
		900: '#1D4044'
	},
	blue: {
		50: '#ebf8ff',
		100: '#ceedff',
		200: '#90cdf4',
		300: '#63b3ed',
		400: '#4299e1',
		500: '#3182ce',
		600: '#2a69ac',
		700: '#1e4e8c',
		800: '#153e75',
		900: '#1a365d'
	},
	cyan: {
		50: '#EDFDFD',
		100: '#C4F1F9',
		200: '#9DECF9',
		300: '#76E4F7',
		400: '#0BC5EA',
		500: '#00B5D8',
		600: '#00A3C4',
		700: '#0987A0',
		800: '#086F83',
		900: '#065666'
	},
	purple: {
		50: '#faf5ff',
		100: '#e9d8fd',
		200: '#d6bcfa',
		300: '#b794f4',
		400: '#9f7aea',
		500: '#805ad5',
		600: '#6b46c1',
		700: '#553c9a',
		800: '#44337a',
		900: '#322659'
	},
	pink: {
		50: '#fff5f7',
		100: '#fed7e2',
		200: '#fbb6ce',
		300: '#f687b3',
		400: '#ed64a6',
		500: '#d53f8c',
		600: '#b83280',
		700: '#97266d',
		800: '#702459',
		900: '#521B41'
	}
};
