import {error} from '../components/ux-standard/toast/toast-service';

export const stringEquals = (firstVal, secondVal) => {
	return firstVal?.trim().toUpperCase() === secondVal?.trim().toUpperCase();
};

const toErrorMessages = async exception => {
	const errorList = [];
	const timeoutMessage =
		'Timeout expired.  The timeout period elapsed prior to completion of the operation or the server is not responding.';
	if (exception?.response?.statusText?.toUpperCase() !== 'OK') {
		try {
			const errors = exception?.response?.data;
			// Check if its validation error
			if (errors?.validationErrors) {
				Object.values(errors.validationErrors).forEach(error => {
					errorList.push(...error);
				});
			} else if (exception instanceof Error && !exception.response) {
				if (exception.message?.length > 0) {
					errorList.push(exception.message);
				} else {
					errorList.push(timeoutMessage);
				}
			}
			// Check if its normal exception
			else if (errors.errors) {
				errors.errors.forEach(error => {
					errorList.push(error.messsage);
				});
			}
			// we are here means somehow unable to connect with api
			else {
				switch (exception.response?.status) {
					case 404:
						errorList.push(
							`Unable to load ${exception.response?.config?.url}`
						);
						break;
					case 504:
						errorList.push(timeoutMessage);
						break;
					default:
						errorList.push(`${exception.message}`);
						break;
				}
			}
		} catch {
			// we got hit badly. Default message
			errorList.push(
				exception && exception.message ? exception.message : 'Unknown Error'
			);
		}
	} else {
		errorList.push(exception.message);
	}

	return errorList;
};

export const showErrors = async (exception, header = 'Please see below error(s) : ') => {
	error([header, await toErrorMessages(exception)]);
};
