import React from 'react';
import {Route, Switch, BrowserRouter as Router} from 'react-router-dom';
import {useStoreState} from 'easy-peasy';
import {GlobalNav} from './components/ux-standard/global-nav/global-nav';
import {PrimaryNav} from './components/ux-standard/primary-nav/primary-nav';
import {MainContent} from './components/ux-standard/main-content/main-content';
import {HomePage} from './pages/home-page';
import {NotFoundPage} from './pages/not-found-page';
import {Footer} from './components/ux-standard/footer/footer';
import {ToastContainer} from './components/ux-standard/toast/toast-container/toast-container';
import {Logout} from './aad/log-out';
import {withAppInsights} from './app-insights';
import {AnalysisDashboard} from './pages/exploration/analysis-dashboard/analysis-dashboard';
import {DataLoadStatus} from './pages/exploration/data-load-status/data-load-status';
import {FacilityAnalysisDashboard} from './pages/exploration/facility-analysis-dashboard/facility-analysis-dashboard';
import {ODARWordAssociation} from './pages/exploration/odar-word-association/odar-word-association';
import {ODARWordCloud} from './pages/exploration/odar-word-cloud/odar-word-cloud';
import {PerformanceDashboard} from './pages/exploration/performance-dashboard/performance-dashboard';
import {VendorInsourcing} from './pages/exploration/vendor-insourcing/vendor-insourcing';
import {SelectData} from './pages/investigation/select-data/select-data';
import {UserLists} from './pages/investigation/user-lists/user-lists';

import {ClaimsResearchTrackingUnited} from './pages/investigation/claims-search-tracking-united/claims-research-tracking-united';
import {ClaimsResearchTrackingCommercial} from './pages/investigation/claims-research-tracking-commercial/claims-research-tracking-commercial';
import {Filter} from './pages/preferences/filter-options/filter-options';
import {SelectDataDefault} from './pages/preferences/select-data-default/select-data-default';
import {CIA} from './pages/tools/cia/cia';
import {MatomoTracking} from './pages/tools/matomo-tracking/matomo-tracking';
import {PAWS} from './pages/tools/paws/paws';
import {ProviderProfiler} from './pages/tools/provider-profile/provider-profiler';
import {TeamTrack} from './pages/tools/team-track/team-track';
import {UserReporting} from './pages/tools/user-reporting/user-reporting';
import {ContactUs} from './pages/support/contact-us/contact-us';
import {HubConnect} from './pages/support/hub-connect/hub-connect';
import {Report} from './pages/support/report/report';
import {CAAgile} from './pages/admin/ca-agile/ca-agile';
import {ManageStopWords} from './pages/admin/manage-stop-words/manage-stop-words';
import {ManageSystemMaintenanceMessage} from './pages/admin/manage-system-maintenance-message/manage-system-maintenance-messages';
import {ManageSystemMaintenanceSwitch} from './pages/admin/manage-system-maintenance-switch/manage-system-maintenance-switch';
import {ManageSystemMessages} from './pages/admin/manage-system-message/manage-system-messages';
import {NetezzaQueryDashboard} from './pages/admin/netezza-query-dashboard/netezza-query-dashboard';
import {PARSGitHub} from './pages/admin/pars-git-hub/pars-git-hub';
import {PARSLoadStatus} from './pages/admin/pars-load-status/pars-load-status';
import {PARSWikiSite} from './pages/admin/pars-wiki-site/pars-wiki-site';
import {QueryRuntimes} from './pages/admin/query-runtimes/query-runtimes';
import {primaryNavModel} from './models/primary-nav';
import {DataGrid} from './pages/investigation/data-grid/data-grid';

const Root = props => <div className="opi-root" {...props} />;

const Layout = () => {
	const config = useStoreState(state => state.appConfig);
	const variant = config.settings.stickyConfig;

	return (
		<Root>
			<Router>
				<GlobalNav variant={variant} />
				<PrimaryNav variant={variant} model={primaryNavModel} />
				<MainContent configsettings={config.settings} variant={variant}>
					<Switch>
						<Route exact path="/" render={() => <HomePage />} />
						<Route exact path="/logout" component={() => <Logout />} />
						<Route
							exact
							path="/exploration/data-load-status"
							component={() => <DataLoadStatus />}
						/>
						<Route
							exact
							path="/exploration/performance-dashboard"
							component={() => <PerformanceDashboard />}
						/>
						<Route
							exact
							path="/exploration/analysis-dashboard"
							component={() => <AnalysisDashboard />}
						/>
						<Route
							exact
							path="/exploration/facility-analysis-dashboard"
							component={() => <FacilityAnalysisDashboard />}
						/>
						<Route
							exact
							path="/exploration/vendor-insourcing"
							component={() => <VendorInsourcing />}
						/>
						<Route
							exact
							path="/exploration/odar-word-cloud"
							component={() => <ODARWordCloud />}
						/>
						<Route
							exact
							path="/exploration/odar-word-association"
							component={() => <ODARWordAssociation />}
						/>
						<Route
							exact
							path="/investigation/select-data"
							component={() => <SelectData />}
						/>
						<Route
							exact
							path="/investigation/user-lists"
							component={() => <UserLists />}
						/>
						<Route
							exact
							path="/investigation/data-grid"
							component={() => <DataGrid />}
						/>
						<Route
							exact
							path="/investigation/claims-research-tracking-united"
							component={() => <ClaimsResearchTrackingUnited />}
						/>
						<Route
							exact
							path="/investigation/claims-research-tracking-commercial"
							component={() => <ClaimsResearchTrackingCommercial />}
						/>
						<Route
							exact
							path="/preferences/filter-options"
							component={() => <Filter />}
						/>
						<Route
							exact
							path="/preferences/select-data-default"
							component={() => <SelectDataDefault />}
						/>
						<Route exact path="/tools/cia" component={() => <CIA />} />
						<Route
							exact
							path="/tools/matomo-tracking"
							component={() => <MatomoTracking />}
						/>
						<Route exact path="/tools/paws" component={() => <PAWS />} />
						<Route
							exact
							path="/tools/provider-profiler"
							component={() => <ProviderProfiler />}
						/>
						<Route
							exact
							path="/tools/team-track"
							component={() => <TeamTrack />}
						/>
						<Route
							exact
							path="/tools/user-reporting"
							component={() => <UserReporting />}
						/>
						<Route
							exact
							path="/support/contact-us"
							component={() => <ContactUs />}
						/>
						<Route
							exact
							path="/support/hub-connect"
							component={() => <HubConnect />}
						/>
						<Route
							exact
							path="/support/report"
							component={() => <Report />}
						/>
						<Route
							exact
							path="/admin/ca-agile"
							component={() => <CAAgile />}
						/>
						<Route
							exact
							path="/admin/manage-stop-words"
							component={() => <ManageStopWords />}
						/>
						<Route
							exact
							path="/admin/manage-system-maintenance-messages"
							component={() => <ManageSystemMaintenanceMessage />}
						/>
						<Route
							exact
							path="/admin/manage-system-maintenance-switch"
							component={() => <ManageSystemMaintenanceSwitch />}
						/>
						<Route
							exact
							path="/admin/manage-system-messages"
							component={() => <ManageSystemMessages />}
						/>
						<Route
							exact
							path="/admin/netezza-query-dashboard"
							component={() => <NetezzaQueryDashboard />}
						/>
						<Route
							exact
							path="/admin/pars-git-hub"
							component={() => <PARSGitHub />}
						/>
						<Route
							exact
							path="/admin/pars-load-status"
							component={() => <PARSLoadStatus />}
						/>
						<Route
							exact
							path="/admin/pars-wiki-site"
							component={() => <PARSWikiSite />}
						/>
						<Route
							exact
							path="/admin/query-runtimes"
							component={() => <QueryRuntimes />}
						/>
						<Route
							path="/privacy-policy"
							component={() => {
								window.open(
									'https://www.optumdeveloper.com/content/odv-optumdev/optum-developer/en/legal-terms/privacy-policy.html'
								);
								return null;
							}}
						/>
						<Route
							path="/terms-of-use"
							component={() => {
								window.open(
									'https://www.optumdeveloper.com/content/odv-optumdev/optum-developer/en/legal-terms/terms-of-use.html'
								);
								return null;
							}}
						/>
						<Route
							exact
							path="/support"
							component={() => {
								window.open('mailto:pars_support@optum.com');
								return null;
							}}
						/>
						<Route render={() => <NotFoundPage />} />
					</Switch>
				</MainContent>
				<Footer />
				<ToastContainer />
			</Router>
		</Root>
	);
};

export default withAppInsights(Layout);
