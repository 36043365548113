import {apiClient} from '../client/index';
const basepath = 'selectdefaults';

export const getSelectDefaultsByMsId = async () => {
	return apiClient()
		.get(`${basepath}`)
		.then(response => response.data);
};

export const saveSelectDefaults = async data => {
	return apiClient()
		.post(`${basepath}`, data)
		.then(response => response.data);
};
