const base = {unit: 'var(--unit)'};

export const styles = {
	baseStyle: {
		container: {
			justifyContent: 'center !important',
			background: 'var(--grey3)',
			height: `calc(4 * ${base.unit})`,
			display: 'flex',
			flexWrap: 'wrap',
			alignItems: 'center',
			marginBottom: '0',
			pr: `calc(2 * ${base.unit})`
		},
		img: {
			width: '98px',
			height: '21px'
		},
		box: {
			display: 'flex',
			alignItems: 'center'
		},
		flex: {
			justify: 'flex-start'
		},
		flexBox: {
			display: 'flex',
			width: '80px',
			justifyContent: 'flex-end'
		}
	},
	sizes: {},
	variants: {
		stickyHeader: {
			container: {top: 0, zIndex: 1031, position: 'fixed', right: 0, left: 0}
		},
		stickyHeaderAndFooter: {
			container: {top: 0, zIndex: 1031, position: 'fixed', right: 0, left: 0}
		}
	},
	defaultProps: {}
};
