import React from 'react';
import {toast} from 'react-toastify';
import {ToastBody} from './toast-body/toast-body';

export const success = (message, options = {}) => {
	const defaultSettings = {
		position: toast.POSITION.TOP_RIGHT,
		autoClose: 10000
	};

	const settings = {...defaultSettings, ...options};

	toast.success(<ToastBody type="success" message={message}></ToastBody>, settings);
};

export const error = (message, options = {}) => {
	const defaultSettings = {
		position: toast.POSITION.BOTTOM_RIGHT,
		autoClose: false
	};

	const settings = {...defaultSettings, ...options};

	toast.error(<ToastBody type="error" message={message}></ToastBody>, settings);
};
//We're not using the below methods, commented it for future use.

/*
export const warn = (message, options = {}) => {
	const defaultSettings = {
		position: toast.POSITION.BOTTOM_RIGHT,
		autoClose: false
	};

	const settings = {...defaultSettings, ...options};

	toast.warn(<ToastBody type="warn" message={message}></ToastBody>, settings);
};

export const info = (message, options = {}) => {
	const defaultSettings = {
		position: toast.POSITION.BOTTOM_RIGHT,
		autoClose: 10000
	};

	const settings = {...defaultSettings, ...options};

	toast.info(<ToastBody type="info" message={message}></ToastBody>, settings);
};
*/
