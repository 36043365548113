import React from 'react';
import {PropTypes} from 'prop-types';
import {Box, Heading} from '@chakra-ui/react';
import {styles} from './error-display.style';

export const ErrorDisplay = props => {
	return (
		<Box sx={styles.container}>
			<Heading size="xl">{'An Error Has Occurred'}</Heading>
			<br></br>
			{props.extraErrorContent}
			<Heading as="h5" size="md">
				{props.errorMessage}
			</Heading>
		</Box>
	);
};

ErrorDisplay.propTypes = {
	errorMessage: PropTypes.string,
	extraErrorContent: PropTypes.node
};

ErrorDisplay.defaultProps = {
	errorMessage: ''
};
