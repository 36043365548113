import React from 'react';
import PropTypes from 'prop-types';
import {styles as styleConfig} from './button.style';
import {Button as ChakraButton, Text} from '@chakra-ui/react';
import {useChakraStyleMerge} from '../../../common/hooks/use-chakra-style-merge';

export const Button = ({
	icon,
	label,
	iconPos = 'left',
	primary,
	children,
	disabled,
	styles,
	...rest
}) => {
	const iconStyles = Object.assign(
		iconPos === 'left' && label?.length
			? {mr: '0.5rem'}
			: iconPos === 'right'
			? {ml: '0.5rem'}
			: {},
		(styles && styles.icon) || {}
	);

	const textStyles = styles && styles.text ? styles.text : {};

	styles = Object.assign(
		primary ? {outlineColor: 'var(--grey1)'} : {},
		styles && styles.default ? styles.default : styles
	);

	const chakraStyles = useChakraStyleMerge({
		styleConfig,
		variant: rest.variant,
		size: rest.size,
		styles
	});

	const renderedIcon = icon && (
		<Text as="span" className={icon} sx={chakraStyles.icon} {...iconStyles}></Text>
	);

	return (
		<ChakraButton disabled={disabled} {...chakraStyles} {...rest}>
			{(iconPos === 'left' || iconPos === 'center') && renderedIcon}
			{label && (
				<Text as="span" {...textStyles}>
					{label}
				</Text>
			)}
			{iconPos === 'right' && renderedIcon}
			{children}
		</ChakraButton>
	);
};

Button.propTypes = {
	children: PropTypes.node,
	disabled: PropTypes.bool,
	icon: PropTypes.string,
	iconPos: PropTypes.string,
	label: PropTypes.string,
	primary: PropTypes.bool,
	styles: PropTypes.object
};
