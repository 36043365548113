import React from 'react';
import {FadeLoader} from 'react-spinners';

export const Spinner = () => {
	return (
		<div
			style={{
				color: '#efefee',
				size: '130px',
				position: 'absolute',
				top: '50%',
				left: '50%',
				transform: 'translate(-50%, -50%)'
			}}
		>
			<FadeLoader data-testid="spinner" loading={true} />
		</div>
	);
};
