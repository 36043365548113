const baseStyles = {
	fontFamily: 'Helvetica Neue, Helvetica, Arial, sans- serif',
	color: '#63666A'
};

export const styles = {
	outer: {
		border: '2px solid #dfdfdd',
		justifyContent: 'center',
		alignItems: 'flex-center',
		flexDirection: 'column',
		width: '100%',
		maxHeight: '500px',
		overflow: 'auto'
	},
	titleComponent: {
		display: 'flex',
		flexGrow: 'true',
		justifyContent: 'space-between',
		alignItems: 'flex-center',
		border: '2px solid black',
		height: '20%',
		width: '100%'
	},
	bodyComponent: {
		display: 'flex',
		flexGrow: 'true',
		border: '2px solid black',
		height: '80%',
		width: '100%',
		overflow: 'auto'
	},
	addfilter: {
		display: 'flex',
		flexGrow: 'true',
		border: '2px solid black',
		width: '20%',
		maxHeight: '500px'
	},
	filterOptions: {
		display: 'flex',
		justifyContent: 'space-between',
		border: '2px solid #dfdfdd',
		width: '90%'
	},
	outerContainer: {
		border: '2px solid #dfdfdd',
		borderRadius: '4px',
		padding: '15px',
		position: 'relative',
		left: '28%',
		width: '42%',
		overflow: 'auto'
	},
	title: {
		fontSize: '30px',
		fontFamily: baseStyles.fontFamily,
		color: baseStyles.color,
		textAlign: 'center'
	},
	subtitle: {
		fontSize: '13px',
		fontFamily: baseStyles.fontFamily,
		fontType: 'bold',
		color: 'black',
		paddingBottom: '2px'
	},
	display: {
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'space-between',
		width: '250px',
		height: '100px',
		backgroundColor: '#efefee',
		borderRadius: '7px',
		boxShadow: '5px 8px 8px #888888'
	},
	radioOrCheckBoxGroup: {
		size: 'sm',
		color: '#63666A',
		fontFamily: 'Helvetica Neue, Helvetica, Arial, sans- serif'
	},
	tabs: {
		_selected: {bg: 'gray.500', color: 'white'},
		bg: 'lightgrey',
		color: 'gray',
		borderRadius: 'md',
		mr: '3px'
	}
};
