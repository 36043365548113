import React from 'react';
import PropTypes from 'prop-types';
import {Controller} from 'react-hook-form';
import {Text} from '@chakra-ui/react';
import {Calendar} from '../ux-standard/calendar/calendar';
import {styles} from './select-data-default.style';

export const DateField = ({label, name, control, defaultValue}) => {
	return (
		<div>
			<Text sx={styles.heading}>{label}</Text>
			<Controller
				name={name}
				control={control}
				defaultValue={defaultValue}
				render={({field: {onChange, value}}) => (
					<Calendar
						size="sm"
						value={new Date(Date.parse(value))}
						onChange={onChange}
						showIcon
						padding="5px"
					/>
				)}
			/>
		</div>
	);
};

DateField.propTypes = {
	label: PropTypes.string.isRequired,
	name: PropTypes.string.isRequired,
	control: PropTypes.object.isRequired,
	defaultValue: PropTypes.instanceOf(Date)
};
