export const formatDate = dateString => {
	if (dateString === null) {
		return ``;
	}
	let date = new Date(dateString);
	let month = (date.getMonth() + 1).toString().padStart(2, '0');
	let day = date.getDate().toString().padStart(2, '0');
	let year = date.getFullYear();
	return `${month}/${day}/${year}`;
};
