import React, {useEffect, useState} from 'react';
import {MsalProvider} from '@azure/msal-react';
import {PublicClientApplication} from '@azure/msal-browser';
import {AppInsightsContext} from '@microsoft/applicationinsights-react-js';
import {useStoreState, useStoreActions} from 'easy-peasy';
import {App} from './app';
import {error} from './components/ux-standard/toast/toast-service';
import {LoaderDisplay} from './components/utilities/loader-display';
import {getAppInsights, reactPlugin} from './app-insights';

export const AppProvider = () => {
	const storeState = useStoreState(state => state);
	const storeActions = useStoreActions(actions => actions);
	const appConfig = storeState.appConfig;
	const loadAppConfigSettings = storeActions.appConfig.getSettings;
	const [isConfigLoading, setIsConfigLoading] = useState(true);
	const [msalInstance, setMsalInstance] = useState(null);
	const [isAppInsightsLoaded, setIsAppInsightsLoaded] = useState(false);

	useEffect(() => {
		(async () => {
			if (loadAppConfigSettings && isConfigLoading) {
				try {
					await loadAppConfigSettings();
					setIsConfigLoading(false);
				} catch {
					error('Unable to fetch configuration data');
				}
			}
		})();
	}, [loadAppConfigSettings]);

	useEffect(() => {
		const appConfigSettings = appConfig.settings;
		if (
			!msalInstance &&
			appConfigSettings.AzureClientId &&
			appConfigSettings.AzureAuthority
		) {
			const azureAdConfig = {
				auth: {
					clientId: appConfigSettings.AzureClientId,
					authority: appConfigSettings.AzureAuthority,
					redirectUri: window.location.origin,
					validateAuthority: true,
					postLogoutRedirectUri: window.location.origin,
					navigateToLoginRequestUrl: false
				},
				cache: {
					cacheLocation: 'sessionStorage',
					storeAuthStateInCookie: false
				}
			};
			const msalInstance = new PublicClientApplication(azureAdConfig);
			setMsalInstance(msalInstance);
		}
	}, [appConfig.settings]);

	useEffect(() => {
		const appConfigSettings = appConfig.settings;
		if (!isAppInsightsLoaded && appConfigSettings.appInsightsConnectionString) {
			const appInsights = getAppInsights(appConfig);
			appInsights.loadAppInsights();

			setIsAppInsightsLoaded(true);
		}
	}, [appConfig.settings, isAppInsightsLoaded]);

	const getComponent = () => {
		if (isConfigLoading) {
			return <LoaderDisplay></LoaderDisplay>;
		}

		if (msalInstance) {
			return (
				<MsalProvider instance={msalInstance}>
					<AppInsightsContext.Provider value={reactPlugin}>
						<App />
					</AppInsightsContext.Provider>
				</MsalProvider>
			);
		}

		return null;
	};

	return getComponent();
};
