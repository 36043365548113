export const styles = {
	title: {
		fontSize: '30px',
		fontFamily: 'Helvetica Neue, Helvetica, Arial, sans- serif',
		color: '#63666A',
		textAlign: 'center'
	},
	outerContainer: {
		display: 'flex',
		alignItems: 'flex-start',
		paddingLeft: '25px'
	},
	container: {
		flex: '1',
		flexDirection: 'column',
		justifyContent: 'space-between'
	},
	tableTitle: {
		fontSize: '18px',
		padding: '0px 0px 1px 0px',
		fontFamily: 'Helvetica Neue, Helvetica, Arial, sans- serif',
		color: '#63666A',
		textAlign: 'center'
	}
};
