import React from 'react';
import {PropTypes} from 'prop-types';
import {Input} from '@chakra-ui/react';
import {KeyFilter} from '../../utilities/keyfilter';
import {useChakraStyleMerge} from '../../../common/hooks/use-chakra-style-merge';
import {styleConfig} from './input.style';

export const InputText = React.forwardRef(
	(
		{
			value,
			disabled = false,
			onKeyPress = null,
			onInput = null,
			keyFilter = null,
			validateOnly = false,
			size,
			variant,
			formControlTestId,
			styles = {},
			...rest
		},
		ref
	) => {
		const keyPressHandler = event => {
			onKeyPress && onKeyPress(event);

			keyFilter && KeyFilter.onKeyPress(event, keyFilter, validateOnly);
		};

		const inputHandler = event => {
			const valid =
				keyFilter && validateOnly && event.target
					? KeyFilter.validate(event.target.value, keyFilter)
					: true;

			onInput && onInput(event, valid);
		};

		const mergedStyles = useChakraStyleMerge({
			themeKey: 'Input',
			size,
			variant,
			styleConfig,
			styles
		});

		return (
			<Input
				ref={ref}
				value={value}
				disabled={disabled}
				testid={formControlTestId}
				onKeyPress={keyPressHandler}
				onInput={inputHandler}
				{...mergedStyles}
				{...rest}
			></Input>
		);
	}
);

InputText.displayName = 'InputText';

InputText.propTypes = {
	className: PropTypes.string,
	css: PropTypes.object,
	disabled: PropTypes.bool,
	keyFilter: PropTypes.string,
	onInput: PropTypes.func,
	onKeyPress: PropTypes.func,
	size: PropTypes.string,
	styles: PropTypes.object,
	validateOnly: PropTypes.bool,
	value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	variant: PropTypes.string,
	formControlTestId: PropTypes.string
};
