import {apiClient} from '../client/index';
const basepath = 'userfilter';

export const deleteUserFilter = async filterId => {
	return apiClient()
		.delete(`${basepath}/${filterId}`)
		.then(response => response.data);
};

export const getUserFilterById = async () => {
	return apiClient()
		.get(`${basepath}/msid`)
		.then(response => response.data);
};

export const setAsDefaultFilter = async filterId => {
	return apiClient()
		.post(`${basepath}/defaultfilter/${filterId}`)
		.then(response => response.data);
};

export const getUserFilterDates = async filterId => {
	return apiClient()
		.get(`${basepath}/dates/${filterId}`)
		.then(response => response.data);
};

export const getUserFilters = async filterId => {
	return apiClient()
		.get(`${basepath}/${filterId}`)
		.then(response => response.data);
};

export const getUserFilterFlags = async filterId => {
	return apiClient()
		.get(`${basepath}/flags/${filterId}`)
		.then(response => response.data);
};

export const updateUserFilter = async (requestData, filterId) => {
	return apiClient()
		.put(`${basepath}/update/${filterId}`, requestData)
		.then(response => response.data);
};

export const getUserFilterByName = async filterName => {
	return apiClient()
		.get(`${basepath}/name/${filterName}`)
		.then(response => response?.data);
};
