import React from 'react';
import {Flex, Text, Tooltip, Image, CloseButton} from '@chakra-ui/react';
import {styles} from './filter-options.style';
import save from '../../../assets/images/save.svg';
import edit from '../../../assets/images/edit.svg';
import trash_delete from '../../../assets/images/trash_delete.svg';
import {Button} from '../../../components/ux-standard/button/button';
import PropTypes from 'prop-types';

export const FilterComponent = ({
	filtername,
	filterid,
	handleSave,
	handleDelete,
	handleSetAsDefault,
	handleEdit,
	handleCancelEditing,
	isEditing,
	isDefaultFilter,
	editingFilterId
}) => {
	const deleteFilter = filterid => {
		handleDelete(filterid);
	};

	const setAsDefault = filterid => {
		handleSetAsDefault(filterid);
	};

	const editFilter = filterid => {
		handleEdit(filterid);
	};

	const saveFilter = filtername => {
		handleSave(filtername);
	};

	const cancelEditingFilter = () => {
		handleCancelEditing();
	};

	return (
		<div style={{paddingTop: '5px'}}>
			<Flex sx={styles.display}>
				{isEditing === true && filterid === editingFilterId ? (
					<Tooltip hasArrow label="Save Filter" placement="top">
						<Image
							src={save}
							style={{cursor: 'pointer'}}
							onClick={() => saveFilter(filtername)}
						/>
					</Tooltip>
				) : (
					<Tooltip hasArrow label="Edit Filter" placement="top">
						<Image
							src={edit}
							style={{cursor: 'pointer'}}
							onClick={() => editFilter(filterid)}
						/>
					</Tooltip>
				)}
				<div>
					<Text textAlign="center" sx={styles.subtitle}>
						{filtername}
					</Text>
					{isDefaultFilter === false ? (
						<Button
							colorScheme="black"
							label="Set as Default"
							size="small"
							onClick={() => setAsDefault(filterid)}
						/>
					) : (
						''
					)}
				</div>
				{isEditing === true && filterid === editingFilterId ? (
					<CloseButton onClick={() => cancelEditingFilter()} />
				) : (
					<Tooltip hasArrow label="Delete Filter" placement="right">
						<Image
							src={trash_delete}
							style={{cursor: 'pointer'}}
							onClick={() => deleteFilter(filterid)}
						/>
					</Tooltip>
				)}
			</Flex>
		</div>
	);
};

FilterComponent.propTypes = {
	filtername: PropTypes.string,
	filterid: PropTypes.number,
	handleSave: PropTypes.func,
	handleDelete: PropTypes.func,
	handleSetAsDefault: PropTypes.func,
	handleEdit: PropTypes.func,
	handleCancelEditing: PropTypes.func,
	isEditing: PropTypes.bool,
	isDefaultFilter: PropTypes.bool,
	editingFilterId: PropTypes.number
};
