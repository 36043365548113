import {styles} from './data-load-status.style';
import React, {useState, useEffect} from 'react';
import {Text, Flex} from '@chakra-ui/react';
import {showErrors} from '../../../common/util';
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {Spinner} from '../../../components/utilities/spinner';
import info_hollow from '../../../assets/images/info_hollow.svg';
import {Tooltip} from 'react-tooltip';
import {getUnitedData, getCommercialData} from '../../../services/data-load-service';
import {formatDate} from '../../../globalFunctions';

export const DataLoadStatus = () => {
	const [unitedData, setunitedData] = useState([]);
	const [commercialData, setCommercialData] = useState([]);
	const [isLoading, setIsLoading] = useState(true);

	useEffect(() => {
		(async () => {
			try {
				const [unitedResponse, commercialResponse] = await Promise.all([
					getUnitedData(),
					getCommercialData()
				]);
				setunitedData(unitedResponse);
				setCommercialData(commercialResponse);
			} catch (error) {
				showErrors(error);
			} finally {
				setIsLoading(false);
			}
		})();
	}, []);

	const loadDateTemplate = rowData => {
		return formatDate(rowData.loadDate);
	};

	const displayText = data => {
		const tooltipContent = (
			<div>
				<strong>{'Load Schedule'}</strong>
				<hr />
				<div dangerouslySetInnerHTML={{__html: data.extendedText}}></div>
			</div>
		);

		if (data.extendedText === null) {
			return `${data.loadSchedule}`;
		}
		return (
			<Flex data-testid="test-tool-tip">
				{`${data.loadSchedule}`}
				<img
					data-tooltip-id={data.dataSource}
					data-tooltip-variant="info"
					src={info_hollow}
					height="16px"
					width="16px"
					alt="not-found"
				/>
				<Tooltip
					id={data.dataSource}
					wordBreak="break-all"
					border="1px solid grey"
					effect="solid"
					opacity="1"
					visible={true}
					place="right-start"
					style={{
						backgroundColor: 'white',
						color: 'grey',
						width: 'auto',
						fontSize: '14px'
					}}
				>
					{tooltipContent}
				</Tooltip>
			</Flex>
		);
	};

	const loadExtendedTextTempalte = rowData => {
		return displayText(rowData);
	};

	const columnStyles = {
		fontSize: '13px',
		fontFamily: 'Helvetica Neue, Helvetica, Arial, sans- serif',
		color: '#63666A',
		fontWeight: 'medium'
	};

	const headerStyles = {
		...columnStyles,
		borderBottom: '2px solid #ddd',
		fontWeight: 'bold',
		color: 'black'
	};

	const loadDateColumn = {
		field: 'loadDate',
		header: 'Load Date ',
		body: loadDateTemplate,
		headerStyle: {...headerStyles}
	};

	const unitedColumns = [
		{
			field: 'dataSource',
			header: 'Data Source',
			headerStyle: {...headerStyles},
			orderBy: 'dataSource'
		},
		{
			field: 'loadSchedule',
			header: 'Load Schedule',
			body: loadExtendedTextTempalte,
			headerStyle: {...headerStyles}
		},
		{
			field: 'loadDate',
			header: 'Load Date',
			body: loadDateTemplate,
			headerStyle: {...headerStyles}
		},
		{
			field: 'dateRange',
			header: 'Date Range',
			headerStyle: {...headerStyles}
		},
		{
			field: 'dataSourceDate',
			header: 'Data Source Date',
			headerStyle: {...headerStyles}
		}
	];

	const commercialColumns = [
		{
			field: 'clientName',
			header: 'Client Name',
			headerStyle: {...headerStyles}
		},
		{
			field: 'platformID',
			header: 'Platform ID',
			headerStyle: {...headerStyles}
		},
		{
			field: 'platformName',
			header: 'Platform Name',
			headerStyle: {...headerStyles}
		},
		loadDateColumn,
		{
			field: 'adjClaimPaidDateRange',
			header: 'Adj Claim Paid Date Range',
			headerStyle: {...headerStyles}
		},
		{
			field: 'feedIDRange',
			header: 'Feed ID Range',
			headerStyle: {...headerStyles}
		}
	];

	return (
		<div data-testid="data-load-status">
			<Text sx={styles.title}>{'Data Load Status'}</Text>
			<Flex data-testid="outer-flex" sx={styles.outerContainer}>
				<Flex data-testid="left-flex" sx={styles.container}>
					<Text sx={styles.tableTitle}>{'United'}</Text>
					<DataTable
						sortField="dataSource"
						tableStyle={{minWidth: '730px'}}
						value={unitedData}
						size="small"
						stripedRows
						showGridlines
					>
						{unitedColumns.map(col => (
							<Column
								key={col.field}
								field={col.field}
								header={col.header}
								body={col.body}
								headerStyle={col.headerStyle}
								bodyStyle={columnStyles}
								orderBy={col.orderBy}
							/>
						))}
					</DataTable>
				</Flex>
				<Flex data-testid="right-flex" sx={styles.container}>
					<Text sx={styles.tableTitle}>{'Commercial'}</Text>
					<DataTable
						tableStyle={{minWidth: '700px'}}
						value={commercialData}
						size="small"
						stripedRows
						showGridlines
					>
						{commercialColumns.map(col => (
							<Column
								key={col.field}
								field={col.field}
								header={col.header}
								headerStyle={col.headerStyle}
								bodyStyle={columnStyles}
							/>
						))}
					</DataTable>
				</Flex>
			</Flex>
			{isLoading && <Spinner data-testid="spinner" />}
		</div>
	);
};
