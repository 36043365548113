import axios from 'axios';
import {store} from '../stores/global';
import jwt_decode from 'jwt-decode';
import {authRequest, acquireToken} from '../aad/auth-utils';

//	Here global state has to be used directly because easy peasy state can only be used using
// react hooks function or react component

export const getBaseUrl = () => {
	const {protocol, hostname, port} = window.location;
	let portOrEmpty = '';
	if (port && port !== '') {
		portOrEmpty = `:${port}`;
	}

	return `${protocol}//${hostname}${portOrEmpty}`;
};

const getToken = async token => {
	const decodeToken = jwt_decode(token);
	const dateNow = new Date();
	const msalInstance = store.getState().msal.instance;

	// checking if token expired
	if (
		!decodeToken ||
		(new Date(decodeToken.exp * 1000) < new Date(dateNow.getTime()) && msalInstance)
	) {
		const request = authRequest();
		token = await acquireToken(request, msalInstance);
		sessionStorage.setItem('accesstoken', token);
	}

	return token;
};

export const apiClient = () => {
	const client = axios.create({
		baseURL: `${store.getState().appConfig.settings.parsApiUrl}`,
		timeout: 1000000
	});
	client.interceptors.request.use(async config => {
		let token = sessionStorage.getItem('accesstoken');
		if (token) {
			token = await getToken(token);
			config.headers.Authorization = `Azure ${token}`;
		}

		config.headers.TimezoneOffset = (-new Date().getTimezoneOffset()).toString();

		return config;
	});

	return client;
};
