import {apiClient} from '../client/index';
const basepath = 'dataload';

export const getUnitedData = async () => {
	return apiClient()
		.get(`${basepath}/united`)
		.then(response => response.data);
};

export const getCommercialData = async () => {
	return apiClient()
		.get(`${basepath}/commercial`)
		.then(response => response.data);
};
