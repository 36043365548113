import React, {useEffect, useState} from 'react';
import {ChakraProvider, extendTheme} from '@chakra-ui/react';
import {useStoreState, useStoreActions} from 'easy-peasy';
import {useMsal, useIsAuthenticated, useAccount} from '@azure/msal-react';
import {InteractionStatus} from '@azure/msal-browser';
import jwt_decode from 'jwt-decode';
import {theme as customTheme} from './theme';
import {PageDataBoundary} from './components/utilities/page-data-boundary';
import Layout from './layout';
import {authRequest} from './aad/auth-utils';
import {getAppInsights} from './app-insights';

const theme = extendTheme(customTheme);

export const App = () => {
	const storeState = useStoreState(state => state);
	const appConfig = storeState.appConfig;
	const storeActions = useStoreActions(actions => actions);
	const setName = storeActions.user.setName;
	const setMsalInstance = storeActions.msal.setInstance;
	const [isAdTokenLoaded, setIsAdTokenLoaded] = useState(false);
	const {instance, accounts, inProgress} = useMsal();
	const isAuthenticated = useIsAuthenticated();

	const onTokenLoaded = () => {
		const token = jwt_decode(sessionStorage.getItem('accesstoken'));
		const emailAttribute = appConfig.settings.UserIdAttribute;
		const {[emailAttribute]: upn} = token.upn;
		localStorage.setItem('upn', token.upn);
		const name = token.name.replace(',', ' ');
		localStorage.setItem('name', name);
		setName(upn);
		setIsAdTokenLoaded(true);
	};

	const dataIsLoaded = () => {
		if (isAdTokenLoaded) {
			return true;
		}

		return false;
	};

	const account = useAccount(accounts[0] || {});

	useEffect(() => {
		if (!storeState.msal.instance) {
			setMsalInstance(instance);
		}

		if (inProgress === InteractionStatus.None && accounts.length === 0) {
			instance.loginRedirect(authRequest());
			return;
		}

		if (inProgress === InteractionStatus.None && accounts.length > 0) {
			const request = {
				...authRequest(),
				account
			};
			instance.acquireTokenSilent(request).then(response => {
				if (response) {
					sessionStorage.setItem('accesstoken', response.accessToken);
					onTokenLoaded();
				}
			});
		}
	}, [account, instance, inProgress]);

	useEffect(() => {
		const appConfigSettings = appConfig.settings;
		if (appConfigSettings.appInsightsConnectionString) {
			const telemetryInitializer = envelope => {
				envelope.tags['ai.user.id'] = 'userId';
				envelope.tags['ai.cloud.role'] = 'React App';
			};
			const appInsights = getAppInsights();
			appInsights.addTelemetryInitializer(telemetryInitializer);
		}
	}, [appConfig.settings]);

	return (
		<ChakraProvider resetCSS theme={theme}>
			<PageDataBoundary itemsToCheck={[appConfig.settings]}>
				{isAuthenticated && dataIsLoaded() ? <Layout /> : null}
			</PageDataBoundary>
		</ChakraProvider>
	);
};
