const grey1 = 'var(--grey1)';
const orange1 = 'var(--orange)';
const base = {
	unit: 'var(--unit)',
	transparent: '2px solid transparent',
	borderLeft: {
		background: 'var(--white)',
		borderLeft: '2px solid var(--orange3)',
		color: grey1
	},
	hoverEffect: {
		textShadow: '0 0 0 black, 0 0 15px var(--orange3), 0 0 5px var(--white)',
		textDecoration: 'none'
	},
	borderBottom: {
		borderBottom: `3px solid var(--orange3)`
	}
};

export const styles = {
	parts: [
		'title',
		'container',
		'navLink',
		'applicationName',
		'topLevelDropDown',
		'secondaryDropDown',
		'secondaryNavLink',
		'separator',
		'logo'
	],
	baseStyle: {
		title: {
			color: 'white',
			fontSize: `calc(1.75 * ${base.unit})`,
			height: `calc(4 * ${base.unit})`,
			lineHeight: `calc(4 * ${base.unit})`,
			paddingLeft: `calc(2.5 * ${base.unit})`,
			paddingRight: `calc(1.5 * ${base.unit})`
		},
		container: {
			height: `calc(9 * ${base.unit})`,
			background: 'white',
			fontSize: `calc(2 * ${base.unit})`,
			padding: '0 0 0 16px',
			boxShadow: '1px 1px 1px rgba(192, 192, 192, 1)'
		},
		navLink: {
			color: 'grey',
			height: `calc(5 * ${base.unit})`,
			display: 'block',
			lineHeight: `calc(5 * ${base.unit})`,
			padding: `0 calc(2 * ${base.unit}) 0 calc(2 * ${base.unit})!important`,
			_hover: {
				...base.hoverEffect
			},
			'& p': {
				color: 'grey'
			},
			'&.active': {
				...base.borderBottom
			},
			_focus: {boxShadow: '1px 1px 1px rgba(256, 192, 192, 0.75)'}
		},
		menus: {
			padding: '23px 0px 0px 0px',
			textAlign: 'left',
			fontFamily: 'Helvetica Neue, Helvetica, Arial, sans- serif',
			fontSize: '14px',
			color: '#666'
		},
		image: {
			justifyContent: 'left',
			paddingTop: '40px',
			paddingRight: '10px',
			width: '32px',
			height: '58px'
		},
		applicationName: {
			display: 'inline-block',
			verticalAlign: 'bottom',
			padding: '0',
			paddingLeft: '20px',
			height: `calc(5.75 * ${base.unit})`,
			color: 'gray.600',
			fontSize: `calc(2.75 * ${base.unit})`
		},
		display: {
			display: 'flex',
			flexWrap: 'wrap',
			flexDirection: 'column',
			justifyContent: 'space-evenly',
			padding: '0px 80px 0px 32px'
		},
		topLevelDropDown: {
			color: 'orange',
			height: `calc(13 * ${base.unit})`,
			borderBottom: '3px solid transparent',
			'& div:first_of_type': {},
			'& .dropdown-menu': {
				minWidth: `calc(8 * ${base.unit})`,
				color: 'orange',
				borderRadius: '0',
				padding: '0',
				marginTop: '13px !important',
				boxShadow: '1px 1px 1px rgba(256, 192, 192, 1)'
			},
			'& .dropdown-toggle:hover': {
				...base.hoverEffect
			},
			'& .dropdown-toggle': {
				padding: `0 calc(2 * ${base.unit}) 0 calc(2 * ${base.unit})!important`,
				lineHeight: `calc(3 * ${base.unit})`,
				height: `calc(3 * ${base.unit})`
			},
			'& .dropdown-item': {
				paddingLeft: '0px',
				paddingRight: '0px',
				borderLeft: base.transparent,
				paddingTop: '10px'
			},
			'& .dropdown-item:hover': {
				...base.borderLeft,
				...base.hoverEffect
			},
			'& .dropdown-item.active': {
				...base.borderLeft
			},
			'& div[class*="nav-item"] a[class*="dropdown-toggle"]': {
				borderLeft: base.transparent,
				color: orange1
			},
			'& div[class*="nav-item"] a[class*="dropdown-toggle"]:hover': {
				...base.borderLeft
			},
			'& .dropdown-toggle:focus-within': {
				...base.borderBottom
			}
		},
		secondaryDropDown: {
			'& .dropdown-toggle': {
				padding: '4px 8px 4px 8px!important',
				lineHeight: '20px',
				height: '40px',
				borderLeft: base.transparent,
				color: 'var(--orange3)'
			},
			'& .dropdown-item': {
				borderLeft: base.transparent
			},
			'& .dropdown-item:hover': {
				...base.borderLeft
			},
			'& .dropdown-menu-right': {
				mr: '2px!important',
				top: '-100px'
			}
		},
		secondaryNavLink: {},
		logo: {
			width: '450px'
		},
		logoBox: {
			paddingTop: '25px',
			paddingLeft: '15px'
		},
		home: {
			padding: '15px 0px 21px 20px',
			width: '90px',
			height: '70px'
		}
	},
	sizes: {},
	variants: {
		stickyHeader: {
			container: {
				top: '10px',
				zIndex: 1030,
				position: 'fixed',
				right: 0,
				left: 0
			}
		},
		stickyHeaderAndFooter: {
			container: {
				top: '10px',
				zIndex: 1030,
				position: 'fixed',
				right: 0,
				left: 0
			}
		}
	},
	defaultProps: {}
};
