import React, {useState} from 'react';
import {Image, Text, Flex} from '@chakra-ui/react';
import {useHistory} from 'react-router-dom';
import PropTypes from 'prop-types';
import {styles} from './tile.style';

export const Tile = ({image, title, link, hoverText}) => {
	const [isHovering, setHovering] = useState(false);
	const history = useHistory();

	const handleImageClick = () => {
		history.push(link);
	};

	const handleMouseEnter = () => {
		setHovering(true);
	};

	const handleMouseLeave = () => {
		setHovering(false);
	};

	return (
		<Flex data-testid="tile" sx={styles.display}>
			<div
				data-testid="hover"
				onMouseEnter={handleMouseEnter}
				onMouseLeave={handleMouseLeave}
				onClick={handleImageClick}
			>
				<Image data-testid="image" sx={styles.img} src={image} alt="image" />
				<Text data-testid="title" sx={styles.text}>
					{title}
				</Text>
				{isHovering && (
					<Text data-testid="hoverText" label={hoverText} sx={styles.hoverText}>
						{hoverText}
					</Text>
				)}
			</div>
		</Flex>
	);
};

Tile.propTypes = {
	image: PropTypes.string.isRequired,
	title: PropTypes.string.isRequired,
	link: PropTypes.string.isRequired,
	hoverText: PropTypes.string.isRequired
};
