import React, {Fragment} from 'react';
import {Link} from 'react-router-dom';
import PropTypes from 'prop-types';
import {
	Flex,
	Box,
	Image,
	Text,
	Stack,
	useStyleConfig,
	Link as ChakraLink,
	Menu,
	MenuButton,
	MenuList,
	MenuItem
} from '@chakra-ui/react';
import logo from '../../../assets/optum-pars.png';
import home from '../../../assets/pars_home.png';
import dropdown from '../../../assets/images/dropdown.png';
import {styles as styleConfig} from './primary-nav.style';
import {primaryNavModel} from '../../../models/primary-nav';

const generateItemNavigation = (styles, item, level = 0) => {
	const {label, items} = item;
	if (!items) {
		return (
			<Box key={`pn_item_${item.label}`}>
				<Link to={item.to}>
					<ChakraLink sx={styles.navLink}>
						{item.icon && <Text as="i" className={item.icon}></Text>}
						<Text>{item.label}</Text>
					</ChakraLink>
				</Link>
			</Box>
		);
	}

	const subMenus = items.map(subItem => {
		let subItemComponent;
		if (subItem.items && subItem.items.length > 0) {
			subItemComponent = generateItemNavigation(styles, subItem, level + 1);
		} else {
			subItemComponent = (
				<Link to={subItem.to}>
					<MenuItem sx={level === 0 ? {} : styles.secondaryDropDown}>
						{subItem.label}
					</MenuItem>
				</Link>
			);
		}

		return (
			<Fragment key={`pn_dropdown_${level}_${label}_${subItem.label}`}>
				{subItemComponent}
			</Fragment>
		);
	});

	return (
		<Menu
			key={`pn_dropdown_${level}_${label}`}
			placement={level > 0 ? 'left' : 'bottom'}
		>
			<MenuButton sx={styles.menus}>{label}</MenuButton>
			<Image src={dropdown} alt="dropdown icon" sx={styles.image} />
			<MenuList>{subMenus}</MenuList>
		</Menu>
	);
};

export const PrimaryNav = ({size, variant}) => {
	const styles = useStyleConfig('PrimaryNav', {styleConfig, size, variant});
	return (
		<Flex direction="row" sx={styles.container}>
			<Link to={'/'}>
				<Stack isInline alignItems="center" h={'100%'}>
					<Box sx={styles.logoBox}>
						<Image src={logo} alt="Optum Logo" sx={styles.logo} />
					</Box>
					<Box sx={styles.home}>
						<Image src={home} alt="Optum Home" sx={styles.home} />
					</Box>
					<Box sx={styles.separator} />
					<Box sx={styles.applicationName}>
						{primaryNavModel.applicationName}
					</Box>
				</Stack>
			</Link>
			<Flex
				id="primary-nav-links"
				direction="row"
				flexGrow={1}
				justifyContent={'flex-start'}
				className="justify-content-left"
			>
				{primaryNavModel.menuData.map(item =>
					generateItemNavigation(styles, item)
				)}
			</Flex>
		</Flex>
	);
};

PrimaryNav.propTypes = {
	size: PropTypes.string,
	variant: PropTypes.string
};
