import React from 'react';
import PropTypes from 'prop-types';
import {
	Flex,
	Box,
	Text,
	Image,
	useMultiStyleConfig,
	Popover,
	PopoverTrigger,
	PopoverContent,
	PopoverBody
} from '@chakra-ui/react';
import {AiFillCaretDown} from 'react-icons/ai';
import {styles as styleConfig} from './global-nav.style';
import jwt_decode from 'jwt-decode';
import styled from 'styled-components';
import Feedback from '../../../assets/images/Feed-back.png';
import {LabelValuePair} from './global-nav-display';

const StyledIcon = styled(AiFillCaretDown)`
	margin-left: 5px;
	margin-top: 7px;
	width: 10px;
	height: 10px;
	color: white;
`;

const StyledText = styled(Text)`
	color: black;
	font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
	padding-top: 5px;
	font-size: 15px;
`;

export const GlobalNav = ({size, variant}) => {
	const styles = useMultiStyleConfig('GlobalNav', {styleConfig, size, variant});
	const token = jwt_decode(sessionStorage.getItem('accesstoken'));
	const name = token.name.replace(',', ' ').split(' ').reverse().join(' ');
	const msid = token.msid;
	const email = token.email;
	const userGroups = token.groups;
	const isAdmin =
		userGroups.includes('AZU_OPTUM_PI_PARS_ADMIN_DEV') ||
		userGroups.includes('AZU_OPTUM_PI_PARS_ADMIN')
			? 'Yes'
			: 'No';
	const isOnShore =
		userGroups.includes('AZU_PARS_US_DEV') || userGroups.includes('AZU_PARS_US')
			? 'Yes'
			: 'No';
	const isOffShore =
		userGroups.includes('AZU_PARS_NONUS') || userGroups.includes('AZU_PARS_NONUS_DEV')
			? 'Yes'
			: 'No';
	const handleImageClick = () => {
		window.open(
			'http://1click.optum.com/Survey/OpenSurvey?ID=1aef1ff0-dad9-4056-82d4-3cfa281052c8',
			'_blank',
			'noopener noreferrer'
		);
	};

	return (
		<Flex sx={styles.container} data-testid="global-nav">
			<Box sx={styles.box} marginLeft="40%">
				<Popover>
					<PopoverTrigger>
						<Box
							data-testid="welcome-text"
							sx={styles.box}
							justifyContent="center"
						>
							<StyledText data-testid="name" style={{color: 'white'}}>
								{`Welcome, ${name}`}
							</StyledText>
							<StyledIcon />
						</Box>
					</PopoverTrigger>
					<PopoverContent>
						<PopoverBody>
							<Flex data-testid="popover-body" direction="column">
								<LabelValuePair
									styles={styles}
									label="Name:"
									value={name}
								/>
								<LabelValuePair
									styles={styles}
									label="MS ID:"
									value={`MS\\${msid}`}
								/>
								<LabelValuePair
									styles={styles}
									label="Email:"
									value={email}
								/>
								<LabelValuePair
									styles={styles}
									label="Admin:"
									value={isAdmin}
								/>
								<LabelValuePair
									styles={styles}
									label="On Shore:"
									value={isOnShore}
								/>
								<LabelValuePair
									styles={styles}
									label="Off Shore:"
									value={isOffShore}
								/>
							</Flex>
						</PopoverBody>
					</PopoverContent>
				</Popover>
			</Box>
			<Box sx={styles.box} marginLeft="5%">
				<Image
					onClick={handleImageClick}
					data-testid="feed-back"
					sx={styles.img}
					src={Feedback}
					alt="Feedback image"
				/>
			</Box>
		</Flex>
	);
};

GlobalNav.propTypes = {
	size: PropTypes.string,
	variant: PropTypes.string
};
