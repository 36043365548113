import {useEffect, useState} from 'react';
import {
	Button,
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalFooter,
	ModalBody,
	Tab,
	Tabs,
	TabList,
	TabPanel,
	TabPanels,
	Text,
	Flex,
	Box,
	Divider
} from '@chakra-ui/react';
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {styles} from './claimDetailsModal.style';
import {FadeLoader} from 'react-spinners';
import {
	getClaimDetailData,
	getClaimQuery,
	getClaimQueryDetailData
} from '../../services/data-grid-service';
import {error} from '../ux-standard/toast/toast-service';
import PropTypes from 'prop-types';
import {showErrors} from '../../common/util';

export const ClaimDetailsModal = props => {
	const {isOpen, onClose, claim} = props;

	const [selectedClaimData, setSelectedClaimData] = useState([]);
	const [flnData, setFlnData] = useState([]);
	const [, setSelectedTab] = useState(0);
	const [isLoading, setIsLoading] = useState(false);
	useEffect(() => {
		const fetchData = async () => {
			try {
				setIsLoading(true);
				const claimResponse = await getClaimDetailData(claim?.claimUid);
				if (claimResponse.length === 0) {
					onMakeClaimQuery();
				} else if (claimResponse.length >= 1) {
					setFlnData(claimResponse);
					onMakeClaimQuery();
				} else {
					error(['No Claim found!']);
				}
			} catch (error) {
				showErrors(error);
			} finally {
				setIsLoading(false);
			}
		};
		if (isOpen) {
			fetchData();
		}
		return () => {
			setSelectedClaimData([]);
		};
	}, [isOpen]);

	const onMakeClaimQuery = async () => {
		const claimQueryResponse = await getClaimQuery(claim.claimUid);
		requestingForDetailData(claimQueryResponse, claim);
	};

	const fetchClaimDeatilData = async (item, claimData) => {
		try {
			const apiConfig = {
				claimIdBase: claimData.claimIdBase,
				claimId: claimData.claimId,
				sqlDetailQueryId: item.sqlDetailQueryId
			};
			const response = await getClaimQueryDetailData(apiConfig);
			if (response.length > 0) {
				let modifiedArray = response.map(obj => {
					return Object.entries(obj).map(([field, val]) => {
						return {field, val};
					});
				});

				onHandlingData(modifiedArray, item.tableName);
			}
			return null;
		} catch (error) {
			showErrors(error);
		}
	};

	const requestingForDetailData = async (claimQuerysArray, claimData) => {
		try {
			await Promise.all(
				claimQuerysArray.map(item => fetchClaimDeatilData(item, claimData))
			);
		} catch (error) {
			showErrors(error);
		}
	};

	const onHandlingData = (data, tabNameVal) => {
		let newData = {tabName: tabNameVal, data: data};
		setSelectedClaimData(prevDataArray => [...prevDataArray, newData]);
		setIsLoading(false);
	};

	const handleTabChange = index => {
		setSelectedTab(index);
	};

	const handleFLNClick = flnNumber => {
		window.open(`https://edms-idrs-ui.uhc.com/idrs/viewer?${flnNumber}`, '_blank');
	};

	return (
		<Modal isOpen={isOpen} onClose={onClose} size="4xl">
			<ModalOverlay />
			<ModalContent>
				<ModalHeader textAlign="center">
					<Flex sx={styles.header}>
						<Box w="20%"></Box>
						<Box w="60%" textAlign="center">
							<Text sx={styles.modalTitle}>
								{'Claim ID Base:'} {claim?.claimIdBase}
							</Text>
							<Text sx={styles.modalSubTitle}>
								{'UID:'} {claim?.claimUid}
							</Text>
						</Box>
						<Button
							sx={styles.headerButton}
							onClick={onClose}
							bg="transparent"
							border="none"
						>
							{'X'}
						</Button>
					</Flex>
				</ModalHeader>
				<Divider />
				<ModalBody id="modal-body">
					<Tabs variant={'unstyled'} size="sm">
						<TabList mb="1em">
							<Tab
								_selected={{bg: 'gray.500', color: 'white'}}
								bg="lightgrey"
								color="gray"
								borderRadius="md"
								mr="3px"
								onClick={() => handleTabChange(claim?.detailData?.length)}
							>
								{'PARS-Claim'}
							</Tab>
							{selectedClaimData?.map((data, index) => (
								<Tab
									_selected={{bg: 'gray.500', color: 'white'}}
									bg="lightgrey"
									color="gray"
									borderRadius="md"
									mr="3px"
									key={index}
									onClick={() => handleTabChange(index)}
								>
									{data?.tabName}
								</Tab>
							))}
							<Tab
								_selected={{bg: 'gray.500', color: 'white'}}
								bg="lightgrey"
								color="gray"
								borderRadius="md"
								mr="3px"
								onClick={() => handleTabChange(claim?.detailData?.length)}
							>
								{'UHC-EDMS'}
							</Tab>
						</TabList>
						<TabPanels>
							<TabPanel>
								<DataTable
									value={selectedClaimData[0]?.data[0]}
									stripedRows
									showGridlines
									size="small"
								>
									<Column
										sortable={true}
										field="field"
										header="Database Field"
									/>
									<Column sortable={true} field="val" header="Value" />
								</DataTable>
							</TabPanel>

							{selectedClaimData?.map((data, index) => {
								return (
									<TabPanel key={index}>
										<DataTable
											value={data?.data[0]}
											stripedRows
											showGridlines
											size="small"
										>
											<Column
												field="field"
												sortable={true}
												header="Database Field"
											/>
											<Column field="val" header="Value" />
										</DataTable>
									</TabPanel>
								);
							})}
							<TabPanel>
								{flnData ? (
									<DataTable
										value={flnData}
										stripedRows
										showGridlines
										size="small"
									>
										<Column
											field="documentType"
											header="Document Type"
										/>
										<Column
											field="flnNumber"
											header="FLN Number"
											body={rowData => (
												<Button
													variant="link"
													color={'blue.300'}
													onClick={() =>
														handleFLNClick(rowData?.flnNumber)
													}
												>
													{rowData?.flnNumber}
												</Button>
											)}
										/>
										<Column
											field="docSentDate"
											header="Doc Sent Date"
										/>
										<Column
											field="vendorDetailCode"
											header="Vendor Detail Code"
										/>
									</DataTable>
								) : null}
							</TabPanel>
						</TabPanels>
					</Tabs>
				</ModalBody>
				<Divider />
				<ModalFooter>
					<Button colorScheme="blue" onClick={onClose}>
						{'Close'}
					</Button>
				</ModalFooter>
				{isLoading && (
					<div
						style={{
							color: '#efefee',
							size: '130px',
							position: 'absolute',
							top: '50%',
							left: '50%',
							transform: 'translate(-50%, -50%)'
						}}
					>
						<FadeLoader data-testid="spinner" loading={isLoading} />
					</div>
				)}
			</ModalContent>
		</Modal>
	);
};

ClaimDetailsModal.propTypes = {
	isOpen: PropTypes.bool.isRequired,
	onClose: PropTypes.func.isRequired,
	claim: PropTypes.shape({
		claimUid: PropTypes.string,
		claimIdBase: PropTypes.string,
		detailData: PropTypes.arrayOf(PropTypes.object)
	})
};
