import {colors} from '../../../theme/colors';

const textLinkBlue = 'var(--textLinkBlue)';

const baseStyles = {
	grey1pxBorder: '1px solid var(--grey6)'
};

const variantButtonBorderless = {
	backgroundColor: 'transparent',
	boxShadow: 'none',
	borderRight: baseStyles.grey1pxBorder,
	color: textLinkBlue,
	display: 'inline-block',
	fontSize: '14px',
	fontWeight: 'normal',
	margin: '0.333rem 0',
	outline: 'none',
	paddingLeft: '8px',
	paddingRight: '8px',
	_active: {
		backgroundColor: 'black',
		color: 'white',
		outline: 'none'
	},
	_disabled: {
		outline: 'none',
		color: 'white',
		backgroundColor: 'black'
	},
	_first: {
		paddingLeft: 0
	},
	_hover: {
		'&:enabled': {
			backgroundColor: 'black',
			color: 'white',
			outline: 'none'
		}
	},
	_last: {
		paddingRight: 0,
		borderRight: 'none'
	}
};

export const styles = {
	parts: ['icon'],
	baseStyle: {
		backgroundColor: 'black',
		display: 'inline-flex',
		alignSelf: 'flex-start',
		alignItems: 'center',
		justifyContent: 'center',
		border: 'none',
		borderRadius: '3px',
		color: 'white',
		icon: {
			color: 'var(--grey1)',
			fontSize: '1rem'
		}
	},
	sizes: {
		small: {
			fontSize: '.7rem',
			minHeight: '1.5rem'
		},
		inline: {
			minHeight: '22px',
			height: '20px',
			padding: '0.1rem 0.5rem',
			mt: '1px'
		},
		medium: {
			fontSize: '1rem',
			minHeight: '2.5rem',
			padding: '0.5rem 1rem',
			margin: '0.2rem'
		},
		large: {
			fontSize: '1.25rem',
			padding: 0
		},
		drawer: {
			width: '200px',
			fontSize: '1rem',
			minHeight: '2.5rem',
			padding: '0.5rem 1rem',
			margin: '0.2rem'
		}
	},
	variants: {
		borderlessDisabled: {
			...variantButtonBorderless,
			icon: {
				color: 'var(--grey3)',
				paddingRight: 'calc(0.5 * var(--unit))'
			}
		},
		borderless: {
			...variantButtonBorderless,
			icon: {
				color: 'rgb(194,86,8)',
				paddingRight: 'calc(0.5 * var(--unit))'
			}
		},
		borderlessCentered: {
			backgroundColor: 'transparent',
			boxShadow: 'none',
			color: textLinkBlue,
			display: 'inline-block',
			fontSize: '18px',
			fontWeight: 'normal',
			margin: 0,
			outline: 'none',
			padding: '0 8px 0 8px',
			_first: {
				paddingLeft: 0
			},
			_hover: {
				'&:enabled': {
					backgroundColor: 'transparent',
					color: textLinkBlue,
					outline: 'none'
				}
			},
			_last: {
				paddingRight: 0,
				borderRight: 'none'
			},
			icon: {
				fontSize: '18px',
				color: 'rgb(194,86,8)',
				paddingRight: '2px',
				paddingLeft: '2px',
				paddingTop: '2px'
			}
		},
		green: {
			border: '2px solid',
			borderColor: 'green.500'
		},
		blue: {
			border: '2px solid',
			borderColor: 'blue.500'
		},
		primary: {
			outlineColor: colors.gray[900]
		},
		link: {
			boxShadow: 'none',
			backgroundColor: 'transparent',
			outline: 'none',
			color: textLinkBlue,
			fontWeight: '400',
			minHeight: '2.5rem',
			padding: '0',
			margin: '0',
			_focus: {
				border: 'none',
				outline: 'none',
				boxShadow: 'none'
			},
			_hover: {
				'&:enabled': {
					backgroundColor: 'transparent',
					color: 'var(--orange3)',
					outline: 'none',
					border: 'none'
				}
			},
			_active: {
				'&:enabled': {
					backgroundColor: colors.white,
					boxShadow: 'none',
					color: colors.gray[900],
					outline: 'none',
					outlineOffset: '-2px'
				}
			},
			_disabled: {
				outline: 'none'
			}
		},
		subform: {
			backgroundColor: '#f7f7f7',
			color: textLinkBlue,
			fontSize: '1rem',
			minHeight: '2.5rem',
			p: '0 0 1rem 0.5rem',
			marginBottom: '0.5rem',
			alignItems: 'center',
			_hover: {
				'&:enabled': {
					backgroundColor: '#dddddd',
					color: 'var(--orange3)'
				}
			},
			_disabled: {
				color: 'var(--grey5)'
			}
		},
		delete: {
			outline: 'none',
			background: '#FFFFFF',
			backgroundColor: '#FFFFFF',
			w: '5px',
			h: '10px',
			minWidth: '5px',
			minHeight: '10px',
			padding: '2px'
		},
		normal: {}
	},
	defaultProps: {
		size: 'medium',
		variant: 'normal'
	}
};
