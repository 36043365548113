import {colors} from '../../../theme/colors';

const baseStyles = {
	border: '1px solid var(--grey6)',
	color: 'var(--grey1)',
	boxShadow: '0 0 5px var(--teal3Alpha50)',
	outline: '1px solid var(--teal3)'
};

export const styles = {
	baseStyle: {
		width: '100%',
		'& .p-button.p-component.p-button-text.p-button-secondary': {
			background: colors.orange[200],
			color: 'white',
			border: colors.orange[300]
		},
		'& .p-inputtext.p-component': {
			background: 'var(--grey6)',
			boxSizing: 'border-box',
			border: baseStyles.border,
			borderRadius: '2px',
			padding: '0 var(--unit)',
			margin: 0,
			color: baseStyles.color
		},
		'& .p-inputtext.p-component:enabled:focus': {
			borderColor: 'var(--grey6)',
			boxShadow: baseStyles.boxShadow,
			outline: baseStyles.outline
		},
		'& .p-inputtext.p-component:enabled:hover': {
			borderColor: 'var(--grey6)'
		},
		'& .p-disabled': {
			cursor: 'default!important'
		},
		'& .p-button.p-button-icon-only': {
			width: '2rem',
			height: '2rem',
			background: colors.orange[200],
			border: colors.orange[300]
		},
		'& .p-button.p-button-icon-only:enabled:focus': {
			borderColor: colors.orange[300],
			boxShadow: baseStyles.boxShadow,
			outline: baseStyles.outline
		},
		'& .p-calendar .p-datepicker.p-component .p-datepicker-group .p-datepicker-calendar-container .p-datepicker-calendar tbody tr td > span':
			{
				height: '15px',
				width: '20px',
				minWidth: '20px'
			},
		'& .p-datepicker p-component p-connected-overlay-enter-done': {
			height: '20px',
			width: '20px',
			minWidth: '20px'
		},
		'& .p-datepicker-title': {
			color: 'grey.600',
			border: '2px solid var(--grey6)'
		}
	},
	sizes: {
		sm: {},
		md: {
			'& .p-inputtext.p-component': {
				marginRight: '0rem',
				height: '1.5rem',
				fontSize: '0.75rem',
				width: '100%',
				minWidth: '50px'
			}
		}
	},
	variants: {},
	defaultProps: {
		size: 'md'
	}
};
