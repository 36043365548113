import {ApplicationInsights} from '@microsoft/applicationinsights-web';
import {ReactPlugin, withAITracking} from '@microsoft/applicationinsights-react-js';

let appInsights;

export const reactPlugin = new ReactPlugin();
export const getAppInsights = appConfig => {
	if (!appInsights) {
		appInsights = new ApplicationInsights({
			config: {
				connectionString: appConfig.settings.appInsightsConnectionString,
				enableAutoRouteTracking: true,
				extensions: [reactPlugin]
			}
		});
	}

	return appInsights;
};

export const withAppInsights = Component => withAITracking(reactPlugin, Component);
