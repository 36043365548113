import React from 'react';
import {PropTypes} from 'prop-types';
import {Calendar as Cal} from 'primereact/calendar';
import {Box} from '@chakra-ui/react';
import {styles as styleConfig} from './calendar.style';
import {useChakraStyleMerge} from '../../../common/hooks/use-chakra-style-merge';

export const Calendar = ({value, size, variant, styles = {}, ...props}) => {
	const chakraStyles = useChakraStyleMerge({
		themeKey: 'Calendar',
		styleConfig,
		variant,
		size,
		styles
	});

	return (
		<Box sx={chakraStyles}>
			<Box
				appendTo={'self'}
				monthNavigator
				yearNavigator
				yearRange="2000:2030"
				showButtonBar
				mask="99/99/9999"
				className="calendar"
				placeholder="mm/dd/yyyy"
				as={Cal}
				sx={chakraStyles}
				{...props}
				value={value}
			></Box>
		</Box>
	);
};

Calendar.propTypes = {
	size: PropTypes.string,
	styles: PropTypes.object,
	value: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.number,
		PropTypes.instanceOf(Date)
	]),
	variant: PropTypes.string,
	selectionMode: PropTypes.string
};
