import React from 'react';
import {CircularProgress} from '@chakra-ui/react';
import PropTypes from 'prop-types';

export const DataBoundary = ({
	children,
	itemsToCheck,
	errorDisplay,
	isLoading,
	loaderDisplay
}) => {
	const checkRequiredData = (...args) => {
		let returnValue = true;
		let item = null;
		for (item of args) {
			if (Array.isArray(item)) {
				if (item.length === 0) {
					returnValue = false;
					break;
				}
			}

			if (!item) {
				returnValue = false;
				break;
			}
		}

		return returnValue;
	};

	return isLoading ? (
		loaderDisplay ? (
			loaderDisplay
		) : (
			<CircularProgress isIndeterminate color="green.300" />
		)
	) : checkRequiredData(...itemsToCheck) ? (
		children
	) : errorDisplay ? (
		errorDisplay
	) : (
		<div>{'Some data is missing'}</div>
	);
};

DataBoundary.propTypes = {
	children: PropTypes.node,
	itemsToCheck: PropTypes.arrayOf(PropTypes.any),
	errorDisplay: PropTypes.object,
	isLoading: PropTypes.bool,
	loaderDisplay: PropTypes.object
};

DataBoundary.defaultProps = {
	children: null,
	itemsToCheck: [],
	isLoading: false,
	errorDisplay: null,
	loaderDisplay: null
};
