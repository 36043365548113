export const styles = {
	header: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center'
	},
	headerButton: {
		marginLeft: 'auto'
	},
	modalTitle: {
		fontSize: '18px',
		fontFamily: 'Helvetica Neue, Helvetica, Arial, sans- serif',
		color: '#63666A',
		textAlign: 'center'
	},
	modalSubTitle: {
		fontSize: '14px',
		fontFamily: 'Helvetica Neue, Helvetica, Arial, sans- serif',
		color: '#63666A',
		textAlign: 'center'
	},
	spinner: {
		color: '#efefee',
		size: '130px',
		position: 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)'
	}
};
